.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-bottom: none !important;
  border-radius: 0 !important;
}

.css-1480iag-MuiInputBase-root-MuiInput-root,
.css-1d3z3hw-MuiOutlinedInput-notchedOutline:before {
  border: none !important;
  border-bottom: none !important;
  left: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  right: 0;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: painted !important;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
  left: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  right: 0;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

label+.css-1480iag-MuiInputBase-root-MuiInput-root {
  margin-top: 16px;
  padding: 10px 0;
}

.trip-in svg {
  color: #d4d4d4;
  font-size: 25px;
  margin-right: 0.5rem;
}

.trip-in input {
  color: #000;
  font-weight: 500;
  font-family: 'Poppins';
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  color: #000 !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}

.trip-in ul {
  display: flex;
}

.search_btn_banner {
  background: hsl(224deg 100% 47%) !important;
  color: #fff !important;
  padding: 10px 50px !important;
  border-radius: 30px !important;
  /* margin-top: 2rem !important; */
  border: none !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background: #00000047 !important;
  padding: 0 50px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: #f0f4f7 !important;
  color: #000 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .makeStyles-tabLabel-23 {
  color: #0543e9;
}

.tb_bnl button {
  margin-right: 0.3rem;
}

.trip-in label span {
  color: #6a737a;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Poppins';
}

.popularItemTd h2 {
  color: #333333;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 0.8rem;
}

.popularItemTd {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
}

.popularItemTd span {
  color: #666666;
  font-size: 20px;
}

.popular_sec {
  padding: 5rem 0;
  position: relative;
}

.cardcon h5 {
  font-size: 25px;
  font-weight: 600;
  color: #333333;
  padding-bottom: 0.5rem;
}

.cardbtns button {
  border: none !important;
  color: #333333;
  background: none !important;
  padding-left: 0;
}

.cardbtns svg {
  color: #b0b0b0;
}

.popular_flot {
  background: hsl(224deg 100% 47%) !important;
  color: #fff !important;
  padding: 10px 50px !important;
  border-radius: 30px !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  bottom: -1rem !important;
  border: none !important;
  text-transform: capitalize !important;
}

.bg_over {
  position: absolute;
  width: 95%;
  background: #f4f9fd;
  z-index: -9;
  content: "";
  top: 70%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 15px;
}

.travelSupportTd h2 {
  color: #333333;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
}

.travelSupportTd h5 {
  color: #666666;
  margin-bottom: 1rem;
}

.travelSupportTd span {
  color: #797979;
  line-height: 2;
  font-size: 15px;
}

.travelSupportImg img {
  position: absolute;
  width: 50%;
  right: 0;
  bottom: 0;
  z-index: -10;
}

.sectionClass {
  padding: 20px 0px 50px 0px;
  position: relative;
  display: block;
}

.fullWidth {
  width: 100% !important;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}


.sectiontitle {
  background-position: center;
  margin: 30px 0 0px;
  text-align: center;
  min-height: 20px;
}

.sectiontitle h2 {
  font-size: 30px;
  color: #222;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}


.headerLine {
  width: 160px;
  height: 2px;
  display: inline-block;
  background: #101F2E;
}


.projectFactsWrap {
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
}


#projectFacts .fullWidth {
  padding: 0;
}

.projectFactsWrap .item {
  width: 25%;
  height: 100%;
  padding: 50px 0px;
  text-align: center;
}

.projectFactsWrap .item:nth-child(1) {
  background: rgb(16, 31, 46);
}

.projectFactsWrap .item:nth-child(2) {
  background: rgb(18, 34, 51);
}

.projectFactsWrap .item:nth-child(3) {
  background: rgb(21, 38, 56);
}

.projectFactsWrap .item:nth-child(4) {
  background: rgb(23, 44, 66);
}

.projectFactsWrap .item p.number {
  font-size: 40px;
  padding: 0;
  font-weight: bold;
}

.projectFactsWrap .item p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin: 0;
  padding: 10px;
  font-family: 'Open Sans';
}


.projectFactsWrap .item span {
  width: 60px;
  background: rgba(255, 255, 255, 0.8);
  height: 2px;
  display: block;
  margin: 0 auto;
}


.projectFactsWrap .item i {
  vertical-align: middle;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.8);
}


.projectFactsWrap .item:hover i,
.projectFactsWrap .item:hover p {
  color: white;
}

.projectFactsWrap .item:hover span {
  background: white;
}

@media (max-width: 786px) {
  .projectFactsWrap .item {
    flex: 0 0 50%;
  }
}


.about-me-img {
  width: 120px;
  height: 120px;
  left: 10px;
  /* bottom: 30px; */
  position: relative;
  border-radius: 100px;
}

/* 
.about-me-img img {
} */


.authorWindow {
  width: 600px;
  background: #75439a;
  padding: 22px 20px 22px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.authorWindowWrapper {
  display: none;
  left: 110px;
  top: 0;
  padding-left: 25px;
  position: absolute;
}

.trans {
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media screen and (max-width: 768px) {
  .authorWindow {
    width: 210px;
  }

  .authorWindowWrapper {
    bottom: -170px;
    margin-bottom: 20px;
  }
}

.banner_top {
  margin-top: 5rem;
}

.trip_sec .makeStyles-tabinner-24 {
  padding: 2rem 3rem !important;
  background: #f1f5f6;
  border-radius: 5px;
}

.trip_price_slider .item {
  padding: 1.5rem 0;
  position: relative;
  text-align: center;
}

.trip_price_slider h3 {
  font-size: 16px;
  line-height: 1.5;
  color: #3d3d3d;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

span.trip_price {
  color: #0b6a01;
  font-weight: 500;
}

.flight_innar {
  border-bottom: 1px solid #cccccc;
  overflow: hidden;
}

.trip_price_slider .item:after {
  background: #ccc;
  bottom: 0;
  content: "";
  height: 50px;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
}

/* .trip_price_slider .item:last-child {
  border-right:none;
} */

.flight_grid_list {
  padding: 5rem 0;
}

.filter_total_price .css-1eoe787-MuiSlider-markLabel:nth-of-type(1) {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.43;
  right: 100%!important;
  letter-spacing: 0.01071em;
  color: #3d3d3d;
  position: absolute;
  white-space: nowrap;
  top: 30px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.filter_total_price .css-1eoe787-MuiSlider-markLabel:nth-of-type(2) {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.43;
  left: 100%!important;
  letter-spacing: 0.01071em;
  color: #3d3d3d;
  position: absolute;
  white-space: nowrap;
  top: 30px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.filter_total_price .css-yafthl-MuiSlider-markLabel {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #3d3d3d;
  position: absolute;
  white-space: nowrap;
  top: 30px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.filter_total_price {
  padding-bottom: 1rem;
}

.accordian_inner .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  position: relative;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border-radius: 0;
  border-top: none;
}

/* .accordian_inner .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
display: none;
} */
p.tab_td {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins';
  color: #3d3d3d;
}

.accordian_inner .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0;
}

.accordian_inner .MuiFormControlLabel-label {
  font-size: 15px;
  font-weight: 400;
  color: #6b747c;
  font-family: 'Poppins';
}

/* .trip_price_slider .active:before {
content: "";
position: absolute;
left: 0;
width: 100%;
height: 2px;
bottom: 0;
background: #0543e9;
} */
.accordian_inner .MuiRadio-colorSecondary.Mui-checked {
  color: #1976d2;
}

.accordian_inner span.MuiIconButton-label,
.MuiCheckbox-root svg {
  font-size: 20px;
}

.accordian_inner .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px;
}

.subscribe_input {
  width: 40%;
  position: relative;
  border: none;
  border-bottom: 1px solid #0000002e;
  padding: 10px 0;
}

input#standard-basic::placeholder {
  font-size: 16px;
  font-family: 'Poppins';
}

.alert {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #309ff2;
  font-family: 'Poppins';
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
  left: 1rem;
}

.testimonial_main_img img {
  width: 100%;
}

.item_testimonial_slider {
  position: relative;
  padding: 20px;
}

img.testimonial_img {
  height: 25%;
  width: 13% !important;
  position: absolute;
  right: 0;
  top: -2rem;
  z-index: 9;
  right: 2rem;
  border-radius: 36px;
  object-fit: cover;
}

.testimonial_slider_content span {
  color: #6a6a6a;
  font-size: 15px;
  line-height: 2;
  font-weight: 400;
}

h5.user_name {
  margin: 1rem 0;
  color: #0742df;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.item_testimonial_slider {
  position: relative;
  padding: 1.5rem 0;
  background: white;
  box-shadow: 0px 1px 15px 1px #c7c7c7;
  border-radius: 10px;
  width: 95%;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 1.5rem;
}

.testimonialSlider .item {
  padding: 0 !important;
}

img.messageImg {
  padding-bottom: 0.5rem;
}

.testimonial_main_img img {
  width: 100%;
  min-height: 80vh;
  max-height: 80vh;
  object-fit: contain;
}

footer p {
  margin-bottom: 0 !important;
}

button.addmore_city_btns {
  position: absolute;
  top: 0;
}
.cardShadow{
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 0px 0px -1px rgb(83 210 247 / 26%), 1px 0px 1px -1px rgb(73 196 237 / 16%), 0px 3px 7px 2px rgb(41 78 86 / 20%)  !important;
  position: relative;
}
.popularBlog {
  position: absolute;
  bottom: 6.5rem;
  right: 0;
  padding: 4px 15px;
  background: #f4c320;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-right: 15px;
}
.popularBlog h6 {
  color: #fff;
  font-size: 12px;
}