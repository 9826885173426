
 .hidden {
  display: none;
}
.navbar {
  width: 100%;
  margin: 0 auto;
}
.navbar .brand {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: inherit;
  text-transform: uppercase;
  color: #252a32;
}
.navbar .menu {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
}
.navbar .menu-item:not(:first-child) {
  margin-left: 2rem;
}

@media only screen and (max-width: 768px) {
  ul.menu svg {
    background: #252a32;
    padding: 5px 5px;
    color: #fff;
    border-radius: 39px;
    margin: auto;
    font-size: 17px;
}
  .navbar {
    margin: 0 auto;
 }
 .navbar .wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* opacity: 0; */
  background: #008ecf;
  transition: all 0.3s ease;
  display: none;
}
.navbar .wrapper .menu {
  display: block;
  flex-direction: column;
  position: absolute;
  top: 25%;
  width: 100%;
  transform: translateY(-50%);
}
  .navbar .wrapper .menu-item {
    padding-bottom: 1rem;
    width: 100%;
    text-align: left;
 }
  .navbar .wrapper .menu-item:nth-child(1) a {
    transition-delay: 0.2s;
 }
  .navbar .wrapper .menu-item:nth-child(2) a {
    transition-delay: 0.3s;
 }
  .navbar .wrapper .menu-item:nth-child(3) a {
    transition-delay: 0.4s;
 }
  .navbar .wrapper .menu-item:nth-child(4) a {
    transition-delay: 0.5s;
 }
  .navbar .wrapper .menu-item:not(:first-child) {
    margin-left: 0;
 }
 .navbar .wrapper .menu-item a {
  padding: 1rem 2rem;
  opacity: 0;
  color: #ffffff !important;
  font-size: 24px;
  font-weight: 600;
  transform: translateX(-20px);
  transition: all 0.3s ease-in-out;
}
 .navbar .nav-toggle {
  display: block;
  z-index: 999;
  position: relative;
}
  .navbar .nav-toggle span {
    display: block;
    width: 20px;
    height: 2px;
    background: #252a32;
    border-radius: 2px;
    margin-left: 14px;
 }
  .navbar .nav-toggle span:nth-child(1) {
    margin-top: 16px;
 }
  .navbar .nav-toggle span:nth-child(2) {
    margin-top: 4px;
    opacity: 1;
 }
  .navbar .nav-toggle span:nth-child(3) {
    margin-top: 4px;
 }
  .navbar #nav:checked + .nav-toggle {
    transform: rotate(45deg);
 }
  .navbar #nav:checked + .nav-toggle span {
    background: #252a32;
    transition: transform 0.5s ease;
 }
  .navbar #nav:checked + .nav-toggle span:nth-child(1) {
    transform: translateY(6px) rotate(180deg);
 }
  .navbar #nav:checked + .nav-toggle span:nth-child(2) {
    opacity: 0;
 }
  .navbar #nav:checked + .nav-toggle span:nth-child(3) {
    transform: translateY(-6px) rotate(90deg);
 }
 .navbar #nav:checked ~ .wrapper {
  z-index: 9999;
  display: block;
}
  .navbar #nav:checked ~ .wrapper .menu-item a {
    opacity: 1;
    transform: translateX(0);
 }
}

li.menu-item a {
  font-weight: 500;
  font-family: 'Poppins' !important;
  color: hsl(0deg 0% 27%);
  padding: 0 0.5rem;
}

.trip-in .css-1tw4lpg .MuiTextField-root {
  width: 100% !important;
}