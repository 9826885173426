body {
  font-family: 'Poppins' !important;
}

.ticket-bg {
  background-image: url(../../asset/images/flight-bg.avif);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Poppins' !important;
  position: relative;
  padding: 6rem 0;
}

.ticket {
  background: #ffffffa3;
  border-radius: 4px;
  color: #0b1521;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  padding: 30px;
  width: 35%;
  position: relative;
  margin: auto;
  box-shadow: 0px 0px 0px 10px #fff;
}

.ticket-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.ticket-header>svg.separator {
  -webkit-animation: flight 2s infinite;
  animation: flight 2s infinite;
  display: block;
  color: #d3e6f4;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
}

.ticket-departure {
  text-align: left;
}

.ticket-destination {
  text-align: right;
}

.ticket .city-abbr {
  color: #3b8acf;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;
}

.ticket .city-name {
  color: #aaaaac;
  font-size: 16px;
  text-transform: uppercase;
}

.ticket .name {
  color: #aaaaac;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
  text-transform: uppercase;
}

.ticket .value {
  color: #000000;
  font-size: 16px;
  line-height: 20px;
}

.flight_ticket_seat .item {
  text-align: center;
}

.row.flight_ticket_seat {
  margin-bottom: 2rem;
  background: #3b8acf;
  padding: 1rem;
  border-radius: 10px;
}

.row.flight_ticket_seat h2 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0.3rem;
  color: #fff;
}

.ticket-header span {
  font-size: 11px !important;
  font-weight: 600;
  font-family: 'Poppins' !important;
  color: #2c3e50 !important;
}

.row.ticket_info .name {
  font-size: 10px;
  color: #696969;
}

.row.ticket_info .item {
  margin-bottom: 0.5rem;
  display: grid;
  text-align: center;
  padding: 5px 0;
  border: 1px solid #dcdcdc;
  background: #ffffffba;
  border-radius: 5px;
}

.row.ticket_info .value {
  color: #000000bf;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
}

.row.flight_ticket_seat .value {
  color: #d9d9d9;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
}

html {
  scroll-behavior: smooth;
}

::selection {
  color: #fff;
  background: #3ea0e2;
}

.wrapper {
  max-width: 80%;
  position: relative;
  margin: auto;
}

.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #2998e9;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.wrapper .row {
  display: flex;
}

.wrapper .row-1 {
  justify-content: flex-start;
}

.wrapper .row-2 {
  justify-content: flex-end;
  margin-top: 4rem;
}

.wrapper .row section {
  background: #eee;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
  display: initial;
}

.wrapper .row section::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}

.row-1 section::before {
  right: -7px;
}

.row-2 section::before {
  left: -7px;
}

.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #f2f2f2;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #3b8acf;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%);
  padding: 8px;
}

.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}

.row-1 section .icon {
  top: 15px;
  right: -60px;
}

.row-2 section .icon {
  bottom: -20px;
  left: -60px;
}

.row section .details,
.row section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row section .details .title {
  font-size: 22px;
  font-weight: 600;
}

.row section p {
  margin: 10px 0 17px 0;
  font-size: 13px;
}

.row section .bottom a {
  text-decoration: none !important;
  background: #2998e9;
  color: #fff !important;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.row section .bottom a:hover {
  transform: scale(0.97);
}

@media(max-width: 790px) {
  .wrapper .center-line {
    left: 40px;
  }

  .wrapper .row {
    margin: 30px 0 3px 60px;
  }

  .wrapper .row section {
    width: 100%;
  }

  .row-1 section::before {
    left: -7px;
  }

  .row-1 section .icon {
    left: -60px;
  }
}

@media(max-width: 440px) {

  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }

  .wrapper .row {
    margin: 10px 0;
  }
}

.header_details .wrapper {
  max-width: initial !important;
  margin: 0 !important;
  padding: 0 20px !important;
  position: initial !important;
}

.bottom i {
  font-size: 14px;
  font-weight: 600;
}

span.date_span {
  font-size: 15px;
  font-weight: 600;
}

.travel-tree {
  padding: 6rem 0;
  padding-top: 3rem;
}

.traveller_list p {
  margin-bottom: 0 !important;
}

.travel_head {
  border-left: 3px solid #1f5ea1;
  padding-left: 1rem;
  font-size: 22px !important;
  margin-bottom: 3rem !important;
}

.traveller_list {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.traveller_list .wrapper-traveller {
  width: 85%;
}