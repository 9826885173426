body {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400;
    color: #000;
    position: relative;
    overflow-x: hidden;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0
}

a,
a:visited,
a:focus,
a:active,
a:hover {
    text-decoration: none;
    outline: none;
}

a,
button {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-family: 'Poppins', sans-serif !important;
}

button {
    cursor: pointer;
    font-family: 'Poppins', sans-serif !important;
}

button:focus {
    outline: 0
}

a {
    color: #2c3e50 !important;
    font-family: 'Poppins', sans-serif !important;
}

a,
a:visited,
a:focus,
a:active,
a:hover {
    text-decoration: none !important;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3 {
    font-weight: 400;
    margin-top: 0;
    font-family: 'Poppins', sans-serif !important;
}

h1 {
    font-size: 70px;
    line-height: 80px
}

h2 {
    font-size: 36px;
    line-height: 46px
}

h3 {
    font-size: 24px;
    line-height: 34px
}

h4 {
    font-size: 20px;
    line-height: 30px
}

h5 {
    font-size: 18px;
    line-height: 28px
}

h6 {
    font-size: 16px;
    line-height: 26px
}

p {
    font-size: 16px;
    color: #777;
    line-height: 26px;
    font-family: 'Poppins', sans-serif;
}

.trip_rad span p {
    color: #67747c;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins';
    letter-spacing: 0;
}

.trip_rad label span {
    color: #0543e9;
}

.trip_select {
    margin-bottom: 1rem
}

/* .trip-in label {} */

.flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 75%;
}

.app-btn {
    color: #fff;
    margin: 10px 5px;
    padding: 6px 0 !important;
    text-align: left;
    border-radius: 5px;
    text-decoration: none;
    font-size: 9px !important;
    text-transform: uppercase;
}

.app-btn.blu {
    transition: background-color 0.25s linear;
}

.app-btn i {
    width: 20%;
    text-align: center;
    font-size: 28px;
    margin-right: 7px;
}

.app-btn .big-txt {
    font-size: 11px;
    text-transform: capitalize;
}

.app-btn p {
    font-size: 13px;
    margin: 0;
    line-height: 1;
    text-align: center;
}

a.app-btn.blu.flex.vert svg {
    color: #3d3d3d;
    font-size: 35px;
    margin-right: 0.5rem;
}

.subscribe_con {
    font-family: 'Poppins' !important;
    font-size: 20px !important;
    color: #3d3d3d !important;
    padding-right: 30px;
}

.subscribe_btn {
    background: #f6c220 !important;
    color: #fff !important;
    padding: 10px 30px !important;
    border-radius: 8px !important;
    height: 50px;
    float: right;
}

.submit_newsbtn_align {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flight_dropdown_details {
    background: #f6f6f6;
}

/* -------------MOBILE----------------- */

@media only screen and (max-width: 768px) {
    img.floght_single_logo {
        width: 55px !important;
        margin: 0 0.5rem !important;
        height: 55px !important;
    }
    .desk_ce::after {
        top: 89%;
        right: 2rem;
    }
    .desk_ce::before {
        top: 89%;
        left: 2rem;
    }
    .ticket_tab_con_head {
        padding-bottom: 0;
    }
    .trip-in ul {
        display: block;
    }
    .book_btm {
        width: 100%;
    }
    .flight_booking_price_list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
    }
    .flight_booking_price_list button.book_tick {
        height: 38px;
        margin-top: 5px;
    }
    .book_btm {
        width: 100%;
        background-color: #f0f4f7;
    }
    .popularItemTd span {
        color: #666666;
        font-size: 15px;
    }

    .travelSupportImg img {
        position: relative;
        width: 100%;
        right: 0;
        bottom: 0;
    }

    .css-1tw4lpg .MuiTextField-root {
        width: 35ch !important;
        margin-bottom: 0rem !important;
        margin-top: 0;
    }

    .ticket_tab_con_head {
        display: flex;
        padding: 1rem 0;
        position: relative;
        text-align: -webkit-center;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .trip_price_slider {
        display: grid;
        justify-content: center;
        position: relative;
    }

    .flight_dropdown_details .item:after {
        display: none;
    }

    .flex {
        width: 100%;
    }

    .app-btn p {
        font-size: 10px;
    }

    .flight_trip_sec.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
        width: 100% !important;
    }

    .trip_sec {
        padding: 1.5rem;
    }

    .banner_sec .booking_select_tab {
        padding: 1rem;
    }

    .subscribe_input {
        width: 100%;
        margin: 1rem 0 !important;
    }

    .traveldateSlide .owl-nav {
        display: block !important;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}

/* -------------TAB----------------- */

@media only screen and (min-width: 768px) and (max-width:1024px) {
    .nav li a {
        padding: 20px 15px;
    }

    .flight_trip_sec .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
        width: 100% !important;
    }

    .trip_sec {
        padding: 1.5rem;
    }

    .flight_trip_sec.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
        width: 100% !important;
    }

}

@media only screen and (min-width: 768px) and (max-width:950px) {
    .ticket_tab_con_head {
        padding: 1rem 0;
        text-align: -webkit-center;
    }
    .trip_price_slider {
        display: grid;
        justify-content: center;
        position: relative;
    }
}

.css-1tw4lpg .MuiTextField-root {
    width: 30ch;
    margin-bottom: 2rem;
    margin-top: 0;
    width: 100% !important;
}

.loginSlide.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    padding-top: 10rem !important;
    left: 0;
    right: 0;
}

.loginSlide.owl-carousel .active span {
    width: 25px;
    height: 10px;
    margin: 5px 7px;
    background: #e9b40a !important;
    display: block;
    backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

.ortextcon {
    text-align: center;
}

h3.ortext {
    color: #878787;
    font-size: 17px;
    font-weight: 500;
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 0;
}

.ortextcon h3:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 200px;
    height: 1px;
    background: #87878740;
    z-index: -2;
}

h3.ortext span {
    background: #ffffff;
    z-index: 99999;
    padding: 0 10px;
}

.login_form input {
    background: #ecf1fc;
    margin: 1.5rem 0 0 0;
    border-radius: 8px;
}

.login_form .form-select {
    background: #ecf1fc !important;
    margin: 1.5rem 0 0 0 !important;
    border-radius: 8px !important;
}

.login_form label {
    color: #516165 !important;
    font-size: 19px !important;
    font-family: 'Poppins';
    margin-left: -0.8rem !important;
    font-weight: 500 !important;
}

.login_form .MuiFormControl-root {
    margin-top: 2rem;
}

.login_form input::placeholder {
    font-size: 15px;
    font-family: 'Poppins';
}

a.txt_field_forget {
    color: #0042f0 !important;
    font-size: 14px;
    font-weight: 500;
}

.forgrt_and_otp {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
}

.login_btn {
    color: #fff;
    padding: 0.6rem 2.5rem;
    font-size: 15px;
    background: #0041f1;
    font-weight: 400;
    margin-left: 1.5rem;
    border-radius: 35px;
    border: none;
    text-transform: uppercase;
}

p.signup_btn {
    font-size: 14px;
    margin: 1rem 0;
}

.form_btns {
    text-align: center;
    margin: 1rem 0 0 0 !important;
}

.form_btns h3.ortext {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
}

.sign_up .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-bottom: none !important;
}

.log_in .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-bottom: none !important;
}

.MuiFormGroup-root.form_check_signup {
    position: relative;
    margin: 0.8rem 0;
}

.MuiFormGroup-root.form_check_signup .MuiFormControlLabel-label {
    font-size: 13px;
    font-family: 'Poppins';
    color: #616161;
}

.register_details_show {
    border: 1px solid #0160d3;
    border-radius: 10px;
    padding: 30px;
    margin-top: 2rem;
    position: relative;
}

.register_details_show input {
    background: none;
    border: 1px solid #0000003b;
    padding: 12px;
}

.register_details_show textarea {
    background: none;
    border: 1px solid #0000003b;
    border-radius: 8px;
}

.annauncement_form {
    display: flex;
    align-items: center;
    width: 85%;
    margin: auto;
    border-top: 1px solid #0000001a;
    border-right: 1px solid #0000001a;
    border-bottom: 1px solid #0000001a;
    border-radius: 5px;
}

.mic_log {
    margin-right: 1rem;
    background: #ffc301;
    padding: 5px 10px;
    line-height: 1;
    border-radius: 5px 0 0 5px;
}

.annount_log {
    padding: 5px 10px;
    line-height: 1;
    border-radius: 0 5px 5px 0;
}

.annount_log span {
    font-size: 15px;
    color: black;
    font-weight: 500;
}

.error {
    color: #f44336;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}

a {
    text-decoration: none;
}

.navbar {
    position: relative !important;
    display: block !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

p.signup_btn a {
    padding-left: 0.5rem;
    color: #0042f0 !important;
}

.trip-in input {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: unset;
}

.trip-in select.form-select {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: unset;
}

.trip_sec .css-1s2u09g-control {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: inherit;
}

.loginSlide.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    padding-top: 10rem !important;
    left: 0;
    right: 0;
}

@media only screen and (max-width: 768px) {
    .loginSlide.owl-theme .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        position: relative !important;
        padding-top: 0rem !important;
        left: 0;
        right: 0;
    }
}

p.otp_num {
    font-size: 18px;
    width: 70%;
    margin: 0 auto;
    margin-top: 2rem;
    color: #566165;
}

form.user_otp div {
    align-items: center;
    justify-content: center;
    padding: 1rem 0 1.5rem 0;
}

form.user_otp input {
    background: #ecf1fc;
    border-radius: 8px;
    border: 1px solid #add2ff;
    color: #000;
    min-width: 60px;
    min-height: 55px;
}

form.user_otp .login_btn {
    margin-left: 0 !important;
}

img.success_img {
    width: 45%;
    margin: 2rem 0;
}

p.send_otp_mail {
    font-weight: 400;
    font-size: 17px;
}

span.mail_bold {
    font-weight: 700;
}

.about-counter {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;
    width: 80%;
}

.about-counter span {
    color: #656565;
    font-size: 21px;
    font-weight: 400;
}

.about-counter .MuiBox-root {
    position: relative;
}

.about-counter .MuiBox-root::after {
    top: 0;
    bottom: 0;
    position: absolute;
    content: "";
    width: 1px;
    height: 50px;
    background: #cbcaca;
    margin: auto;
    left: -4rem;
}

.about-counter .MuiBox-root:first-child:after {
    display: none;
}

.about-counter h2 {
    color: #0040f0;
    font-weight: 500;
}

.ourexperience_area {
    padding: 5rem 0 3rem 0;
}

.ourexperience_area_inner span {
    color: #6a6a6a;
    font-size: 15px;
    line-height: 2;
    font-weight: 400;
}

.ourexperience_area_inner h5 {
    color: #6a6a6a;
    font-size: 22px;
    font-weight: 400;
}

.ourexperience_area_inner h2 {
    color: #333333;
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
}

.experience_slider_content h3 {
    color: #333333;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.5;
}

.experience_slider_content {
    position: relative;
    padding: 15px;
    box-shadow: 0px 2px 15px 0px #c7c7c7;
    margin-bottom: 2rem;
}

.review {
    padding: 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

ul {
    padding: 0 !important;
    margin: 0 !important;
}

span.total_review {
    margin-left: 0.5rem;
    font-size: 11px;
    line-height: 3;
}

.review svg {
    font-size: 18px;
}

.blog_tag ul li {
    display: inline-block;
    margin: 0 1rem 0 0;
}

.blog_tag ul li span {
    border-radius: 30px;
    background: #e9e9e9;
    font-size: 11px;
    padding: 3px 10px;
}

.experience_slider {
    margin-top: 2rem;
}

.experienceSlide .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 45%;
    margin: auto;
    left: 0;
    right: 0;
    margin: auto;
}

.experienceSlide {
    position: relative;
}

.experienceSlide .owl-nav button.owl-prev {
    position: absolute;
    left: -2rem;
    bottom: -1rem;
    background: white !important;
    z-index: 9999 !important;
    width: 3rem;
    height: 3rem;
    font-size: 30px;
    border-radius: 50px !important;
    box-shadow: 0px 0px 10px 0px #4e4e4e;
}

.experienceSlide .owl-nav button.owl-next {
    position: absolute;
    right: -2rem;
    bottom: -1rem;
    background: white !important;
    z-index: 9999 !important;
    width: 3rem;
    height: 3rem;
    font-size: 30px;
    border-radius: 50px !important;
    box-shadow: 0px 0px 10px 0px #4e4e4e;
}

.ourexperience_area {
    position: relative;
}

.bg_overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70%;
    background: #f9f9f9;
    z-index: -9;
}

img.quote_img {
    width: 14% !important;
    padding-bottom: 1.5rem;
}

span#emailHelp {
    color: red !important;
}

.css-igs3ac {
    border: none !important;
}

.home .trip-in {
    background: white;
    padding: 0;
}

.css-1s2u09g-control {
    border: none !important;
    /* border-bottom: 1px solid #cccccc40 !important; */
    border-radius: inherit !important;
}

.home_flight_search .trip-in {
    padding: 0;
}

.rectangle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    width: 50px;
    height: 50px;
    background: #0041f1;
    transform: scale(0);
    border-radius: 50%;
    color: white;
    opacity: 0;
    font-weight: 700;
    overflow: hidden;
    animation: scale-in 0.3s ease-out forwards, expand 0.35s 0.25s ease-out forwards;
    z-index: 999999999;
    top: 5rem;
    right: 2rem;
    margin: auto;
}

.notification-text {
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    animation: fade-in 0.65s ease-in forwards;
}

@keyframes scale-in {
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes expand {
    50% {
        width: 350px;
        border-radius: 6px;
    }

    100% {
        width: 300px;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -1px rgba(0, 0, 0, 0.12);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.8;
    }
}

.bg-red {
    background-color: red;
}


.loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    border-color: #dcdee2;
}

.loader.full {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: 0 auto;
    transform: translateY(-50%);
}

.loader:before {
    content: "";
    font-family: fontawesome;
    color: #ffffff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid transparent;
    position: absolute;
    border-color: #6EB200;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 0 solid transparent;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    animation: loader-spin ease-in-out 2s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    font-size: 30px;
}

.loader img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }

    70% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    background: #0000006b;
    z-index: 9999999;
    height: 100vh;
}

.cursor-pointer {
    cursor: pointer;
}

.banner_box {
    background: #f0f4f7;
    padding: 2rem;
    border-radius: 5px;
}

.banner_box .search_btn_banner {
    margin-top: 0.7rem !important;
}

.banner_box .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-bottom: 1px solid #cccccc40;
    border-radius: inherit;
}

.hotel_search .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-bottom: 1px solid #cccccc40;
    border-radius: inherit;
}

.room_guest button {
    display: block;
    width: 100%;
    padding: 0 0.75rem;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.5;
    color: #212529;
    background-color: #f0f4f7;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: none;
}

.banner_box label {
    color: #6a737a;
    /* font-size: 20px; */
    font-weight: 400;
    font-family: 'Poppins';
    width: max-content;
    position: relative !important;
}

.register-select-option {
    margin-top: 55px;
    z-index: 9999;
}

.login_btn:disabled {
    background-color: grey;
    cursor: not-allowed;
}

.banner_box label {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}

.banner_box .css-1okebmr-indicatorSeparator {
    display: none;
}

.offerSlide .owl-nav {
    display: block !important;
    position: absolute;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    height: 25%;
    margin: auto !important;
}

.offerSlide .owl-dots {
    display: none !important;
}

.offer_slider_inner {
    display: flex;
    justify-content: space-around;
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px #bdbdbd;
    margin: 1rem 5px;
}

.offer_slider_img {
    width: 30%;
    display: inline-block;
}

.offer_slider_img img {
    max-height: 125px;
    min-height: 125px;
    width: 100% !important;
    object-fit: cover;
    border-radius: 0px 5px 5px 5px;
    box-shadow: 10px 10px 0px 0px #e5e5e5;
}

.offer_slider {
    padding: 2rem 0 0;
    background: #f4f9ff;
}

.offer_slider_con h4 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.offer_p {
    text-transform: capitalize;
    font-size: 15px;
    color: #656565;
}

.offer_slider_con ul {
    display: flex;
    justify-content: space-between;
}

.offer_btn {
    margin: 0 !important;
}

.offer_slider_con ul li p {
    font-size: 12px;
    color: black;
    font-weight: 600;
    margin-bottom: 0;
}

.offer_slider_con {
    margin-top: 1rem;
}

.offer_slider_con ul li p span {
    font-size: 16px;
}

.offerSlide .owl-nav button.owl-prev {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
}

.offerSlide .owl-nav button.owl-next {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
}

.owl-theme .owl-nav [class*='owl-']:hover {
    background: none;
    color: #0543e9;
    text-decoration: none;
}

/* .offerSlide .owl-nav button.owl-prev span {
    border-radius: 50px;
    background: #fff;
    position: relative;
    right: 1.5rem;
    font-size: 33px;
    padding: 0 1.2rem;
    box-shadow: 0px 0px 4px 0px #0000005c;
}
.offerSlide .owl-nav button.owl-next span {
    border-radius: 50px;
    background: #fff;
    position: relative;
    left: 1.5rem;
    font-size: 33px;
    padding: 0 1.2rem;
    box-shadow: 0px 0px 4px 0px #0000005c;
} */
.offerSlide .owl-nav button.owl-prev {
    position: absolute;
    left: -2rem;
    background: white !important;
    z-index: 9999 !important;
    width: 3rem;
    height: 3rem;
    border-radius: 50px !important;
    box-shadow: 0px 0px 10px 0px #4e4e4e;
    font-size: 25px;
    color: #0346f5;
    line-height: 2;
}

.offerSlide .owl-nav button.owl-next {
    position: absolute;
    right: -2rem;
    background: white !important;
    z-index: 9999 !important;
    width: 3rem;
    height: 3rem;
    border-radius: 50px !important;
    box-shadow: 0px 0px 10px 0px #4e4e4e;

}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #00000047;
}

/* .popular_detail_sec h4 {
    position: absolute;
    left: 6rem;
    bottom: 3rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
} */

.popular_search .banner_box {
    background: #f0f4f7;
    padding: 1rem 0;
}

.popular_search .banner_box .form-control {
    background: #f0f4f7;
}

.popular_search .banner_box .css-1s2u09g-control {
    background: #f0f4f7;
}

.popular_inner_imgs img {
    width: 100%;
    border-radius: 10px;
    margin: 1rem 0;
}

.popular_detail_con {
    padding: 2rem 0;
}

.popular_quote h5 {
    font-size: 24px;
    font-weight: 500;
}

.popular_quote {
    margin-bottom: 2rem;
    border-left: 3px solid #f7be28;
    padding-left: 1rem;
}

.place_details_content {
    color: #6e6e6e;
    font-size: 15px;
    line-height: 30px;
}

.popular_place .popular_quote h5 {
    text-transform: uppercase;
    font-size: 18px;
}

.pop_card {
    position: absolute;
    z-index: 9999;
    bottom: 0;
}

.pop_card h5 {
    color: #fff;
    text-transform: capitalize;
}

.pop_card span {
    color: #fff;
}

.pop_nearest_hotels h5 {
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.nearest_hotel_slider {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 6rem;
}

.near_plcess_details button {
    border: 1px solid #eaeaea !important;
    padding: 8px 10px;
    border-top: none !important;
    border-bottom: none !important;
    width: 50%;
    border-radius: 0;
    color: #fff;
    display: flex;
    justify-content: flex-start;
}

.near_plcess_details_list .type {
    border: 1px solid #eaeaea !important;
    padding: 8px 15px;
    border-left: none !important;
    border-top: none !important;
    width: 50%;
    border-radius: 0;
    color: #fff;
}

.near_plcess_details_list .city {
    border: 1px solid #eaeaea !important;
    padding: 8px 15px;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    width: 50%;
    border-radius: 0;
    color: #fff;
}

.home .trip_sec {
    padding: 2rem;
    background: #ffffff;
}

.popular_hotel_card h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 0.3rem;
    /* min-height: 80px; */
    line-height: 30px;
}

.popular_hotel_card h6 {
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.popular_hotel_card h6 span {
    font-size: 11px;
    color: #bababa;
    font-weight: 400;
    margin-left: 0.5rem;
    display: block;
}

.hotel_card_con {
    font-size: 15px;
    color: #6c6c6c;
    padding: 0.7rem 0;
    margin-bottom: 0;
}

.hotel_btn {
    background: hsl(224deg 100% 47%) !important;
    color: #fff !important;
    padding: 6px 25px !important;
    border-radius: 30px !important;
    font-size: 14px;
}

.hotel_btn_info {
    font-weight: 500;
    color: #8f8270;
    text-decoration: underline !important;
    font-size: 14px;
    text-transform: uppercase;
}

.hotel_card_btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hotel_card_list_btns {
    text-align: center;
}

.hotel_card_contentbox {
    padding: 1rem;
    border: 1px solid #e3e3e3;
    border-radius: 0px 0px 8px 8px;
}

.border-gray-color {
    border: 1px solid #e3e3e3 !important;
}

.hotel_card_list_contentbox {
    text-align: center;
    background-color: rgb(247 247 247);
    border-radius: 0 8px 8px 0;
}

.list-color {
    color: rgb(120 120 120) !important;
}

.nearhotel_card_img img {
    border-radius: 8px 0 0 8px;
}

.nearhotel_card_img_grid img {
    border-radius: 8px 0 0 8px;
}

a.hotel_view_.btn {
    background: #f7be28 !important;
    color: #fff !important;
    padding: 10px 40px !important;
    border-radius: 30px !important;
    margin: auto !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    text-transform: capitalize;
    display: flex;
}

.nearestHoterSlider .owl-nav {
    display: block !important;
    position: absolute;
    top: -6rem;
    right: 0rem;
}

.nearestHoterSlider .owl-nav button {
    background: white !important;
    z-index: 9999 !important;
    width: 3rem;
    height: 3rem;
    border-radius: 50px !important;
    box-shadow: 0px 0px 10px 0px #4e4e4e;
    color: #0543e9 !important;
}

.hotel_review {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.nearhotel_card_img {
    overflow: hidden;
    position: relative;
    border-radius: 8px 0 0 8px;
    border: 1px solid #e3e3e3;
}

.nearhotel_card_img_grid {
    overflow: hidden;
    position: relative;
    border-radius: 8px 8px 0 0;
    border: 1px solid #e3e3e3;
}

.hotel_review_inner {
    padding: 5px 10px;
}

span.trip_blog {
    color: #f7be28;
    font-size: 14px;
}

.hotel_filter_sec .css-1kz0hui-MuiSlider-valueLabel {
    font-family: 'Poppins', sans-serif !important;
    z-index: 1;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: -10px;
    transform-origin: bottom center;
    -webkit-transform: translateY(-100%) scale(0);
    -moz-transform: translateY(-100%) scale(0);
    -ms-transform: translateY(-100%) scale(0);
    transform: translateY(-100%) scale(0);
    position: absolute;
    background-color: #ffffff;
    border-radius: 2px;
    color: #a9a9a9;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0.25rem 0.75rem;
}

.hotel_filter_sec .css-yafthl-MuiSlider-markLabel {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #a9a9a9;
    position: absolute;
    white-space: nowrap;
    top: -1.5rem;
    right: -9rem;
}

.hotel_filter_sec h6 {
    font-size: 24px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e1e1e1;
}

.filter_slide_sec span {
    font-size: 16px;
    font-weight: 500;
}

.filter_slide_sec .css-1aznpnh-MuiSlider-root {
    margin-bottom: 0;
}

.filter_slide_sec.MuiBox-root.css-1v5z18m {
    border-bottom: 1px solid #e1e1e1;
}

.filter_slide_sec.MuiBox-root.css-0 {
    border-bottom: 1px solid #e1e1e1;
}

.filter_accordian.MuiBox-root.css-0 .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
    flex-direction: inherit !important;
}

.filter_accordian.MuiBox-root.css-0 .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
    flex-direction: inherit !important;
    background: none;
    padding: 0;
}

.filter_accordian .css-ta84vm-MuiAccordionDetails-root {
    border-top: none !important;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.filter_accordian .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.Mui-expanded.css-1fjvggn-MuiPaper-root-MuiAccordion-root {
    border: none !important;
}

.filter_accordian .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.css-1fjvggn-MuiPaper-root-MuiAccordion-root {
    border: none !important;
}

.hotel_filter_sec {
    width: 90%;
}

.hotel_filter_sec .MuiAccordionSummary-content.css-1betqn-MuiAccordionSummary-content p {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #212529;
}

.hotel_filter_sec .css-1aznpnh-MuiSlider-root {
    border-radius: 12px;
    box-sizing: content-box;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    color: #0148de;
    -webkit-tap-highlight-color: transparent;
    height: 6px;
    width: 100%;
    padding: 14px 0;
    margin-bottom: 20px;
}

.hotel_filter_check {
    display: flex;
    justify-content: space-between;
}

.hotel_filter_check ul li label span {
    color: #6e6f71;
    font-size: 16px !important;
    font-family: 'Poppins';
}

.hotel_filter_check ul li svg {
    color: #c3c3c3;
    font-size: 22px !important;
    font-family: 'Poppins';
}

.hotel_list_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.grid_shape ul li {
    display: inline-flex;
}

.hotel_sort_by .css-88nv5c-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    display: none !important;
}

li.sort_by label {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #717578;
}

.grid_shape ul li {
    display: inline-flex;
    padding: 0 5px;
}

.grid_shape ul li:nth-child(3) {
    border-left: 1px solid #bababa;
}

.grid_shape ul li:nth-child(2) svg {
    color: #5e92ff;
}

.total_hotel span {
    color: #ffffff;
    font-weight: 400;
    font-size: 15px;
}

.hotel_list {
    margin-bottom: 5rem;
}

.horizontalCard.nearhotel_card_img {
    width: 25%;
    float: left;
}

.horizontalCard.nearhotel_card_img_grid {
    width: 25%;
    float: left;
}

.hori_card_con .near_plcess_details {
    display: flex;
    justify-content: space-evenly;
}

.hori_card_con .hotel_card_contentbox {
    padding: 1rem;
    border: none;
    background: #f1f1f1;
}

.hori_card_con {
    display: inline-block;
    width: 100%;
}

.hori_view_btn {
    width: 25%;
    display: inline-block;
}

.horizontalCardMain {
    display: flex;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    width: 95%;
    margin: auto;
    margin-bottom: 2rem;
    justify-content: space-between;
}

.hori_card_con .near_plcess_details button {
    border-top: 0;
}

.hori_view_btn {
    display: grid;
    align-items: center;
    text-align: center;
    background: #f7f7f7;
    padding: 0 1rem;
}

.hotel_card_tag {
    background: #f3c710;
    font-size: 10px;
    padding: 5px 10px;
    position: relative;
    top: 0;
    left: 1.4rem;
    border-radius: 5px 6px 0px 5px;
    box-shadow: 1px 3px 4px 1px #c0c0c05e;
    color: #fff;
}

.hotel_card_tag svg {
    font-size: 15px;
    margin-right: 0.3rem;
}

.hotel_payment.MuiBox-root.css-1wnsr1i {
    border: 0;
    border-radius: 20px;
    width: 100% !important;
    position: relative;
}

/* .MuiBox-root.css-1wnsr1i {
    border: 0;
    border-radius: 20px;
    width: 100% !important;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translate(0%, 0%);
} */

.hotel_search_field_box h6 {
    color: #6c7073;
    font-size: 15px;
    font-weight: 400;
}

.hotel_search_field {
    display: flex;
    justify-content: start;
    padding-top: 3rem;
    padding-bottom: 1rem;
}

.hotel_search_field_box {
    padding-right: 4rem;
}

.hotel_search_field_box span {
    font-size: 16px;
    font-weight: 500;
    padding-left: 0.5rem;
}

.hotel_search_field_result svg {
    color: #173ccb;
    font-size: 25px;
}

.hotel_search_field_result {
    display: flex;
    align-items: center;
}

.hotel_payment_head h5 {
    font-size: 23px;
    font-weight: 600;
    color: #000000;
    line-height: 30px;
    letter-spacing: 1px;
}

.hotel_payment .MuiBox-root.css-1wnsr1i {
    padding: 0;
}

.hotel_payment .MuiContainer-root.MuiContainer-maxWidthLg {
    padding: 0 !important;
}

span.pay_login {
    display: block;
    color: #6a6a6a;
    padding: 1.5rem 0 0.5rem 0;
    font-size: 14px;
}

.payment_form_head h6 {
    font-size: 20px;
    color: #282828;
    margin-bottom: 2rem;
}

.payment_form_head {
    border-left: 3px solid #1f5ea1;
    padding-left: 1rem;
}

form.payment_form_details label {
    color: #616161;
    margin-bottom: 0.5rem;
    font-size: 15px;
}

.hotel_payment_address input {
    background: #f8f8f8;
    border: 1px solid #e3e3e3;
    margin-bottom: 1rem;
}

.hotel_payment_address select {
    background: #f8f8f8;
    border: 1px solid #e3e3e3;
    margin-bottom: 1rem;
}

.hotel_payment_address textarea {
    background: #f8f8f8;
    border: 1px solid #e3e3e3;
    margin-bottom: 1rem;
}

label.offer_check {
    color: #212529 !important;
    font-family: 'Poppins';
}

form.payment_form_details label a {
    text-transform: uppercase;
}

.flight_pop_contentent.MuiBox-root.css-i9gxme .flight_pop_total {
    width: 90%;
    float: right;
}

.result_details_head span {
    color: #fff;
    z-index: 999;
    position: relative;
    font-weight: 300;
}

.result_details_head .hotel_search_field_box {
    padding-right: 0;
}

.hotel_result_amt {
    color: #fff;
    align-items: center;
    padding: 0;
    display: flex;
    position: relative;
    z-index: 9;
    padding-right: 0 !important;
}

.hotel_result_amt button {
    margin-top: 0 !important;
}

.taxResult {
    position: absolute !important;
    bottom: 0px;
    font-size: 9px !important;
    color: #bababa !important;
}

.hotel_result_amt h2 {
    display: flex;
    font-size: 40px;
    align-items: center;
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 1px solid #787878;
}

.result_details_head .hotel_search_field {
    align-items: center !important;
    justify-content: space-evenly;
    padding: 0;
}

.hotel_result_amt h2 p {
    color: #cac6c3;
    font-weight: 300;
    margin-left: 0.5rem;
}

.result_details_head {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    right: 0;
    margin: auto;
}

.result_hotel_name_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    background: linear-gradient(to right, #2c3e50, #0d7fcb) !important;
    border-inline: 5px solid #faaf00;
    border-radius: 15px;
}

.result_hotel_name h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #fff;
}

.result_hotel_location {
    display: flex;
    align-items: center;
}

.result_hotel_location h6 {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
}

.result_hotel_location svg {
    color: #faaf00;
    font-size: 32px;
    margin-right: 0.5rem;
}

.result_hotel_details_td h6 {
    border-bottom: 1px solid #efefef;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
}

.result_hotel_details_sec p {
    color: #6b6b6b;
    font-weight: 400;
    line-height: 1.8;
    font-size: 15px;
    margin-bottom: 1rem;
}

.result_hotel_images {
    display: flex;
    justify-content: space-between;
}

.result_hotel_images img {
    width: 23%;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.hotel_result_sm_td {
    font-size: 18px;
    margin-bottom: 0.5rem;
}

.room_review_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    background: #fff;
    padding: 1rem;
    border-inline: 4px solid #1976d2;
    border-radius: 5px 15px 5px 15px;
    margin-bottom: 1rem;
    position: relative;
}

.review_user_name span {
    color: #1976d2;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
}

.reiew_lists p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.review_user_name {
    display: flex;
    justify-content: space-between;
}

.room_review_inner img {
    width: 8%;
    border-radius: 50%;
    margin-right: 1.5rem;
}

.room_review_inner_con p {
    color: #6b6b6b;
    font-weight: 400;
    line-height: 1.8;
    font-size: 13px;
    letter-spacing: 0.8px;
    text-align: justify;
}

.review_commend_form h6 {
    font-size: 17px;
    padding: 1rem 0;
}

.review_commend_form input,
textarea::placeholder {
    color: #9a9a9a;
    font-size: 13px;
}

.room_review {
    background: #f7f7f7;
    padding: 2rem;
}

button.submit {
    background: #f6c022;
    padding: 0.5rem 1.5rem;
    color: #fff;
}

.room_review input,
textarea {
    border: 1px solid #ced4da54 !important;
}

.hotel_result_inner_search label {
    color: #767a7d;
    font-size: 14px;
}

.hotel_result_inner_search input {
    border: none;
}

.hotel_result_inner_search input {
    font-size: 14px;
    border: none;
}

.hotel_result_inner_search button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1rem;
    margin: auto;
    width: 45%;
    padding: 7px 0 !important;
}

.hotel_result_inner_search {
    background: #f7f7f7;
    padding: 2rem;
    margin-bottom: 3rem;
    position: relative;
}

.amenties_box_list ul li {
    color: #626262;
    line-height: 3;
    display: flex;
    font-size: 15px;
}

.amenties_box_list {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
}

.amenities_icon {
    position: relative;
}

.amenities_icon::before {
    content: "";
    position: absolute;
    left: -10px;
    width: 25px;
    top: 5px;
    height: 25px;
    background: #eeb669;
    z-index: -1;
    border-radius: 50%;
}

.amenities_icon {
    position: relative;
    margin-right: 0.5rem;
}

.amenties_box {
    padding-bottom: 1.5rem;
}

.extra_searvice_list {
    color: #737373;
    display: flex;
    justify-content: space-between;
}

.extra_searvice_list p {
    font-size: 14px;
    margin-bottom: 0.3rem;
}

.hotel_result_blog_slider .item_experience_slider img {
    min-height: 200px;
    max-height: 199px;
    object-fit: cover;
}

.hotel_result_blog .ourexperience_area {
    padding: 0;
}

.hotel_result_blog .experienceSlide .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 25%;
    margin: auto;
    left: 2.5rem;
    right: 2.5rem;
    margin: auto;
}

.hotel_result_blog .result_hotel_details_td h6 {
    border-bottom: 1px solid #efefef;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.owl-nav {
    display: block !important;
}

.hotel_result_blog.experienceSlide .owl-carousel .owl-nav {
    display: block;
}

.result_related_hotels h2 {
    color: #0f1021;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3rem;
}

.result_related_hotels {
    background: #f7f7f7;
    margin-top: 5rem;
    padding: 3rem 0 5rem 0;
}

.related_hotel_slider {
    margin-bottom: 2rem
}

.hotel_result {
    margin-bottom: 0 !important;
}

.blogBannerInner img {
    width: 100%;
    height: 100%;
    min-height: 60vh;
    max-height: 60vh;
    object-fit: cover;
}

.blogBannerInner {
    position: relative;
}

.blogBannerInner_content {
    position: absolute;
    z-index: 9999 !important;
    color: #fff;
    left: 5rem;
    bottom: 2rem;
}

.blogBannerInner h2 {
    font-size: 50px;
    font-weight: 600;
}

.blogBannerInner span {
    font-weight: 300;
    font-size: 20px;
}

.blogBanner .owl-nav {
    position: absolute;
    bottom: 0;
    right: 10rem;
    margin: auto;
}

.blogBanner .owl-nav {
    position: absolute;
    bottom: 0;
    right: 10rem;
    margin: auto;
    background: #ffffff;
    border-radius: 10px 10px 0 0;
}

.blogBanner .owl-nav button.owl-prev {
    padding: 0 15px !important;
    border-right: 1px solid #d3d3d3;
    border-radius: unset;
}

.blogBanner .owl-nav button.owl-next {
    padding: 0 15px !important;
    border-radius: unset;
}

.recent_blog_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recent_blog_head h3 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
}

.recent_blog_head h3::before {
    content: "";
    width: 3rem;
    height: 0.3rem;
    background: #0543e9;
    position: absolute;
    top: -1rem;
    border-radius: 5px;
    left: 0;
}

.recent_blog_head {
    padding-bottom: 1.5rem;
}

.recent_blog {
    margin: 3rem 0;
    position: relative;
    padding: 2rem 0;
}

.recent_blog_head button.book_tick {
    background: #f6c220 !important;
}

.recent_bennr_head {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.recent_bennr_head h5 {
    color: #ffffff;
    font-size: 18px;
    position: relative;
    margin-bottom: 1rem;
}

.recent_banne_name h6 {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.recent_banne_name span {
    font-size: 13px;
    font-weight: 300;
}

.date_txt {
    font-size: 10px !important;
    text-transform: uppercase;
}

.bloger_like_share {
    display: flex;
    justify-content: space-between;
}

.recent_banne_name h5 {
    color: #fff;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 400;
}

.bloger_like_share .css-1wlk0hk-MuiAvatar-root {
    width: 30px;
    height: 30px;
}

.bloger_like_share .MuiAvatar-root {
    width: 30px;
    height: 30px;
}

.recent_banne_blogers {
    position: relative;
}

.red {
    color: red !important;
}

.RecentNewsInner_content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    width: 97%;
    margin: auto;
    padding: 0.5rem 1rem;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}

h5.recent_bennr_head_txt {
    width: 95%;
    margin: auto;
    margin-bottom: 0.8rem;
    text-transform: capitalize;
}

.bf-l:before {
    content: "";
    width: 1px;
    height: 1.5rem;
    position: absolute;
    background: #909090;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -2rem;
}

.af-l:after {
    content: "";
    width: 1px;
    height: 1.5rem;
    position: absolute;
    background: #909090;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -2rem;
}

.blogRecentNews_img {
    height: 100%;
    min-height: 55vh;
    max-height: 55vh;
}

.blogRecentNews {
    border-radius: 10px;
    overflow: hidden;
}

.blogBannerRecentNews .owl-nav {
    display: none !important;
}

.blogBannerRecentNews .owl-dots {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
}

.blogBannerRecentNews_tag svg {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #0fb565;
}

.blogBannerRecentNews .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #f6c220;
}

.blogBannerRecentNews .owl-dots .owl-dot span {
    margin: 0;
    margin-right: 0.5rem;
}

.blogeSingle_img {
    margin: auto;
    width: 35px !important;
    height: 35px !important;
}

.blogHorizontal_card_img {
    width: 35%;
    display: inline-block;
    float: left;
    margin-right: 1rem;
}

.blogHorizontal_card_img img {
    width: 100%;
    border-radius: 10px;
    min-height: 140px;
    max-height: 140px;
    object-fit: cover;
}

.horizontal_bager_td h2 {
    font-size: 18px;
    color: #353535;
    font-weight: 500;
    text-transform: capitalize;
    padding-top: 1rem;
    min-height: 4rem;
}

.blogHorizontal_card {
    box-shadow: 0px 0px 6px 1px #c4c4c4;
    border-radius: 10px;
    width: 100%;
    background: #ffffff;
    padding: 0.8rem;
    display: inline-block;
    margin-bottom: 1rem;
}

.horizontal_bager_name {
    display: flex;
    justify-content: space-between;
}

.horizontal_bager_name .css-1wlk0hk-MuiAvatar-root {
    width: 30px;
    height: 30px;
    margin: auto;
}

.horizontal_bager_name h6 {
    font-size: 12px;
    font-weight: 400;
    color: #888888;
}

.horizontal_bager_name span {
    font-weight: 400;
    color: #888888;
}

.horizontal_bager_name svg {
    color: #d6e0e9;
}

.horizontal_bager_td h5 {
    font-size: 12px;
    font-weight: 600;
    color: #353535;
}

.horizontal_bager_td h5 span {
    font-weight: 400;
    margin-right: 0.5rem;
}

.horizontal_bager_td {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bt-l {
    border-top: 1px solid #ececec;
    padding-top: 0.5rem;
}

.recent_news_overlay {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background: #f4f9ff;
    top: 0;
    height: 40vh;
    z-index: -1;
    width: 90%;
    margin: auto;
    border-radius: 20px;
}

.blog_ver_card span {
    font-family: 'Poppins';
}

.blog_ver_card .css-et1ao3-MuiTypography-root {
    margin: 0;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: block;
}

.blog_ver_card .css-83ijpv-MuiTypography-root {
    font-size: 12px;
}

.blog_ver_card {
    min-width: 100% !important;
    padding: 1rem !important;
    padding-top: 0 !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 10px 0px #c6c5c58c !important;
    margin-bottom: 1rem;
}

.verCard_img {
    border-radius: 10px;
}

.blog_ver_card .horiCard_like_share h5 {
    font-weight: 600;
    font-size: 13px;
}

.blog_ver_card .horiCard_like_share {
    display: flex;
    align-items: center;
}

.blog_ver_card .horizontal_bager_name {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000001c;
    padding-bottom: 0.7rem;
}

.verCard_content {
    color: #a8a8a8;
    font-size: 12px;
}

.verCard_title {
    color: #313131;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 0.5rem 0;
    text-transform: capitalize;
}

.verCard_img_sec {
    position: relative;
}

.verCard_img_sec span {
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    background: #fcf5e2;
    padding: 3px 10px;
    color: #e6bf27;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 500;
}

.blog_ver_card .horiCard_like_share h5 span {
    margin-right: 0.5rem;
}

.sufferingOn_catogory {
    text-align: center;
    padding: 2rem 0;
    background: #fff;
}

.al-c {
    text-align: center;
}

.main_before_blog {
    background: #f4f9ff;
    padding: 3rem 0;
}

.main_before_blog .subscribe_btn {
    margin-top: 2rem;
}

.sufferingOn_catogory h3 {
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 500;
}

.catogories_btns button {
    padding: 10px 20px;
    background: antiquewhite;
    margin: 0 0.7rem;
    text-transform: capitalize;
    font-size: 16px;
}

.catogories_btns .catogories_btn_1 {
    color: #9a2955;
    background: #fdedf7;
}

.catogories_btns .catogories_btn_2 {
    color: #1d8668;
    background: #dbfef7;
}

.catogories_btns .catogories_btn_3 {
    color: #555384;
    background: #e7e7ff;
}

.catogories_btns .catogories_btn_4 {
    color: #7b9c29;
    background: #e8fdb8;
}

.catogories_btns .catogories_btn_5 {
    color: #edbf26;
    background: #fcf5dc;
}

.catogories_btns .catogories_btn_6 {
    color: #151515;
    background: #e3e7ea;
}

.catogories_btns .catogories_btn_7 {
    color: #1276c6;
    background: #d5ebf9;
}

.horiCard_like_share_icon svg {
    color: #cccfd4;
    margin: 0 0 0 0.5rem;
}

.recent_banne_blogers svg {
    margin: 0 0 0 0.5rem;
}

.bloggerCard {
    box-shadow: 0px 0px 12px 0px #00000030;
    position: relative;
    display: grid;
    text-align: center;
    background: #ffffff;
    border-radius: 1rem;
    margin: 2rem 0;
    padding: 1.5rem 1rem;
}

.bloggerCard img {
    width: 85px !important;
    min-height: 85px;
    max-height: 85px;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
}

.bloggerCard h4 {
    font-size: 14px;
    color: #505050;
    font-weight: 600;
    margin-top: 1.5rem;
    padding-bottom: 0.5rem;
}

.bloggerCard span {
    font-size: 11px;
    color: #818181;
    font-weight: 500;
}

.bloggerSlider .owl-nav {
    display: block !important;
    position: absolute;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    height: 25%;
    margin: auto !important;
}

.bloggerSlider .owl-nav button.owl-prev {
    position: absolute;
    left: -2rem;
    background: white !important;
    z-index: 9999 !important;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50px !important;
    box-shadow: 0px 0px 10px 0px #4e4e4e;
    font-size: 25px;
    color: #0346f5;
}

.bloggerSlider .owl-nav button.owl-next {
    position: absolute;
    right: -2rem;
    background: white !important;
    z-index: 9999 !important;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50px !important;
    box-shadow: 0px 0px 10px 0px #4e4e4e;
    font-size: 25px;
    color: #0346f5;
}

.blogers_section {
    background: #f4f9ff;
    padding: 4rem 0 2rem 0;
}

.css-17lvz0h-MuiPaper-root-MuiCard-root {
    box-shadow: 0px 0px 10px 0px #00000045 !important;
}

.blog_list_section {
    position: relative;
    padding-top: 5rem;
    padding-bottom: 2rem;
}

.blog_list_pagination {
    display: flex;
    align-items: center;
    margin: 2rem 0 3rem 0;
}

.blog_list_pagination button.MuiButtonBase-root.Mui-disabled.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-rounded.Mui-disabled.MuiPaginationItem-previousNext.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    background: #e6e6e6;
}

.blog_list_pagination button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-rounded.MuiPaginationItem-previousNext.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    background: #e6e6e6;

}

.blog_list_pagination ul li button {
    opacity: 100% !important;
    margin: 0 !important;
    border: none !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
}

.blog_list_pagination ul {
    background: white;
    box-shadow: 0px 0 5px 2px #e6e6e6;
    border-radius: 10px;
}

.blog_details_userDetails {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
}

.user_profile_avatar .MuiAvatar-root.MuiAvatar-circular {
    margin: auto;
}

.blog_details_userDetails .MuiAvatar-root.MuiAvatar-circular.MuiAvatarGroup-avatar {
    width: 30px;
    height: 30px;
}

.blog_detail_heading h2 {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #010c2c;
}

.blog_detail_heading button {
    padding: 4px 15px;
    border: 1px solid;
    font-size: 13px;
}

.blog_user_detail {
    margin-top: 4rem;
}

.blog_detail_heading {
    text-align: center;
}

.blog_detail_banner_inner {
    width: 70%;
    margin: auto;
}

.blog_detail_banner {
    background: #f1f5f6;
    padding-bottom: 1rem;
    padding-top: 4rem;
}

.user_profile_avatar .blogHorizontal_card_name h6 {
    font-size: 12px;
}

.blogDetailSlider .owl-nav button.owl-prev {
    left: -1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 20px;
    color: #8a8a8a;
}

.blogDetailSlider .owl-nav button.owl-next {
    right: -1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 20px;
    color: #8a8a8a;
}

.blog_details_content_section {
    padding: 3rem 0 0 0;
}

.blog_details_content_slider {
    width: 70%;
    display: block;
    margin: auto;
    position: relative;
}

.blgDetail_banner_inner img {
    border-radius: 1rem;
    width: 100%;
    min-height: 45vh;
    max-height: 45vh;
    object-fit: cover;
}

.blogDetailSlider_content span {
    color: #6f6f6f;
    font-size: 15px;
    padding-bottom: 1rem;
    display: block;
    line-height: 1.6;
}

.blogDetailSlider {
    margin-bottom: 1.5rem;
}

.blogDetailSlider_content h5 {
    color: #343434;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0.5rem 0;
}

.blogDetailSlider_quote {
    display: flex;
    border: 1px dotted #00000042;
    border-right: none;
    border-left: none;
    padding: 1.5rem 0;
    padding-bottom: 0;
}

.blogDetailSlider_quote span {
    padding-bottom: 0;
}

.blogDetailSlider_quote svg {
    font-size: 5rem;
    color: #bfbfbf;
    position: relative;
    top: -2rem;
}

.blogDetailSlider_imageContent img {
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 0.5rem;
}

.blogDetailSlider_image {
    width: 100%;
}

.blogDetailSlider_imageContent {
    margin: 2rem 0 2rem 0;
}

.blogDetailSlider_social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #bdbdbd;
    border-left: none;
    border-right: none;
    padding: 1rem 0;
}

.socialMedias_list {
    display: flex;
    align-items: center;
}

.socialMedias_list h6 {
    font-size: 15px;
    color: #4a4a4a;
    margin-right: 0.5rem;
}

.socialMedias_list svg {
    font-size: 33px;
    padding: 7px;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.fb_cb {
    color: #fdffff;
    background: #3b5999;
}

.twit_cb {
    color: #fdffff;
    background: #00acee;
}

.link_cb {
    color: #fdffff;
    background: #0996cb;
}

.pin_cb {
    color: #fdffff;
    background: #cb2028;
}

.blogDetails_commend_form {
    padding: 2rem 0;
}

.blogDetails_commend_form button span {
    padding-bottom: 0;
    color: #fff;
    font-size: 12px !important;
}

.relatedBlogs .owl-nav {
    display: none !important;
}

.blogDetail_relatedBlogs {
    background: #f7f7f7;
    padding: 4rem 0 5rem 0;
}

.create_blog_head {
    padding: 1rem 0;
    background: #031953;
}

.create_blog_head ul {
    text-align: end;
}

.create_blog_head ul li {
    display: inline-block;
    position: relative;
}

.create_blog_head ul li a {
    color: #cfd7e7 !important;
    text-transform: uppercase;
    font-size: 13px;
    margin-left: 2rem;
    font-weight: 500;
    position: relative;
}

.create_blog_head ul li a.active::after {
    background: #0041f4;
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    right: 0;
    bottom: -1rem;
}

.upload_blog_details {
    padding: 2rem 0 5rem 0;
    background: #f9fafc;
}

.upload_blog_form .form-group {
    margin-bottom: 1.5rem;
}

span.tox-statusbar__branding {
    display: none !important;
}

.blog_bg_drop .MuiDropzoneArea-root {
    width: 100%;
    border: dashed;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    min-height: 100%;
    border-color: transparent;
    border-radius: 4px;
    background-color: #fff;
}

.blog_bg_drop_sec {
    align-items: center;
    display: flex;
    border-style: dashed;
    border-color: #d6d8d7;
}

.blog_bg_drop_sec {
    align-items: center;
    display: flex;
    border: dashed 1px #c1c1c1;
    border-radius: 0.5rem;
    padding: 1rem;
    background: white;
    padding-left: 2rem;
}

img.blog_bg_drop_sec_img {
    width: 10%;
}

.blog_bg_drop .MuiDropzoneArea-textContainer p {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #2655d3;
    position: relative;
}

.blog_bg_drop .MuiDropzoneArea-textContainer {
    text-align: left;
    margin-left: 1rem;
}

.blog_bg_drop .MuiDropzoneArea-textContainer p::after {
    content: "Support : Jpeg,png";
    position: absolute;
    bottom: -1.2rem;
    left: 0;
    font-size: 12px;
    color: #9e9e9e;
}

.blog_bg_drop .MuiDropzoneArea-icon {
    display: none;
}

.blog_bg_drop .MuiDropzoneArea-text {
    margin-top: 0;
    margin-bottom: 24px;
}

.setUs_background {
    display: flex;
    align-items: center;
}

.setUs_background img {
    margin-right: 0.5rem;
    width: 14%;
    background: #ffffff !important;
    padding: 7px;
    border-radius: 0.7rem;
    border: 1px solid #d8d8d8;
}

.setUs_background_text h5 {
    color: #747577;
    font-size: 14px;
    font-weight: 500;
}

.setUs_background_text a {
    font-size: 12px;
}

.set-background_sec {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 1rem;
}

.set-background_sec .setUs_background {
    flex-basis: 48%;
}

.blog_bg_drop_sec .MuiDropzonePreviewList-image {
    color: rgba(0, 0, 0, 0.87);
    width: initial;
    opacity: 1;
    z-index: 5;
    object-fit: contain;
    height: 70%;
    max-width: 70%;
    box-shadow: rgb(0 0 0 / 12%) 0 1px 6px, rgb(0 0 0 / 12%) 0 1px 4px;
    box-sizing: border-box;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-radius: 4px;
    margin-top: 1rem;
    border-radius: 0;
}

.blog_bg_drop_sec .MuiDropzonePreviewList-removeButton {
    top: 1rem;
    right: 2rem;
    width: 30px;
    height: 25px;
    opacity: 0;
    position: absolute;
    transition: .5s ease;
}

.blog_bg_drop_sec svg {
    width: 15px;
}

.blog_date_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.blog_postDate {
    width: 60%;
    display: inline-block;
}

.blog_preview_btn button {
    color: #909090;
    border: 1px solid #ced4da;
    font-size: 12px;
    font-weight: 600;
}

.blog_preview_btn button svg {
    margin-right: 0.5rem;
}

.blog_preview_btn {
    text-align: end;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.upload_blog_details label {
    color: #6b6c6e;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}

.blog_date_time input {
    color: #535353;
    font-size: 14px;
    font-weight: 500;
}

.date_gategory_section select.form-control {
    color: #535353;
    font-size: 14px;
    font-weight: 500;
}

.date_gategory_section input {
    color: #535353;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
}

.blog_catagies_select {
    margin-bottom: 1rem;
}

.blog_tag_select {
    margin-bottom: 2rem;
}

.create_blog_publish button {
    background: #327703;
    width: 100%;
    padding: 0.8rem 0;
    color: #fff;
}

.blog_catagies_select .chip {
    padding: 4px 10px;
    background: #ebf2fa;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 11px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 19px;
    color: #57585c;
    font-weight: 600;
}

.blog_user_profile {
    background: #f1f5f6;
    position: relative;
    padding: 1.5rem 0 2.5rem 0;
}

.blog_user_profile_card_img img {
    width: 160px !important;
    min-height: 160px !important;
    max-height: 160px !important;
    border-radius: 50%;
    border: 3px solid #ffffff;
    box-shadow: 0px 0px 10px 1px #b4b4b48a;
    margin-bottom: 1rem;
    object-fit: cover;
}

.blog_user_profile_content h5 {
    color: #182b5d;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.3rem;
}

span.blogs_count {
    color: #7c7c7c;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    display: inline-block;
}

.blog_user_profile_content h6 {
    color: #7a7a7a;
    font-size: 14px;
    border-top: 1px solid #7a7a7a54;
    display: inline-block;
    margin-top: 0.2rem;
    padding-top: 0.2rem;
}

.blog_user_profile_content span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
    color: #6a6a6a;
    font-size: 14px;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-weight: 500;
}

.blog_user_profile_content span.MuiSwitch-thumb.css-jsexje-MuiSwitch-thumb {
    color: #1f9e35;
}

.blog_user_profile_card {
    background: #fff;
    text-align: center;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px 1px #b4b4b44a;
    width: fit-content;
    margin: auto;
    margin-top: 2rem;
}

.blog_user_profile_card label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.css-j204z7-MuiFormControlLabel-root {
    display: inline-block;
    width: 100%;
}

.blog_user_personal_details .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
    color: #6f7072;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 3rem 0px 0;
}

.blog_user_personal_details .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #2e3f66 !important;
}

.blog_user_personal_details .css-heg063-MuiTabs-flexContainer {
    border-bottom: 1px solid #d5d5d591;
    justify-content: space-evenly;
}

.blog_user_personal_details .MuiBox-root-40 {
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.blog_personalDetails label {
    color: #696b6c;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 0.3rem;
}

.blog_personalDetails input {
    color: #868686;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 400;
    padding: 10px 10px;
}

.blog_personalDetails button {
    background: hsl(224deg 100% 47%) !important;
    color: #fff !important;
    padding: 10px 40px !important;
    border-radius: 30px !important;
    margin-top: 1rem;
    letter-spacing: 0;
    font-size: 13px;
}

.blog_personalDetails .MuiBox-root.MuiBox-root-42 {
    padding: 0;
}

.blog_yourStory_followers {
    background: #f9fafc;
    padding: 3.5rem 0 5rem 0;
}

.blog_yourStory_card img {
    width: 70%;
    min-height: 120px;
    max-height: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.blog_yourStory_card h5 {
    font-size: 17px;
    text-transform: capitalize;
    color: #3a3a3a;
    font-weight: 600;
}

.blog_yourStory_card {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    text-align: center;
    padding: 1.5rem 0;
    width: 95%;
    margin: auto;
    border-radius: 0.8rem;
}

.blog_followers_img img {
    width: 85%;
    min-height: 60px;
    max-height: 60px;
    object-fit: cover;
    border-radius: 50%;
    display: inline-block;
    margin: auto;
}

.blog_followers_img {
    width: 25%;
    display: inline-block;
}

.blog_followers_name h6 {
    color: #3b3c3e;
    font-size: 18px;
    text-transform: capitalize;
}

.blog_followers_name span {
    color: #6b6c6e;
    font-size: 13px;
}

.blog_followers_Datalist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e3e5;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
}

.blog_followers_img_content {
    display: flex;
    align-items: center;
}

a.viewAll_ul {
    text-transform: uppercase;
    color: #898a8c;
    font-size: 13px;
    text-decoration: underline !important;
    font-weight: 500;
}

.flight_payment_pop .MuiBox-root.css-1wnsr1i {
    border: 0;
    border-radius: 20px;
    width: 75% !important;
    position: relative;
    left: 0;
    top: 50%;
    bottom: 0;
    right: 0;
    transform: translate(0%, -50%);
    margin: auto;
}

.flight_payment_pop .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgb(0 0 0 / 53%) !important;
}

.payment_add_conatct_address label {
    font-size: 13px;
    font-weight: 500;
    color: #3a3a3a;
}

.edit_traveller_pop_inner {
    width: 40%;
    margin: auto;
    text-align: center;
}

.traveller_add_form select {
    width: 100%;
}

.wrapper-traveller {
    width: 100%;
    display: flex;
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    background: #f9f9f9;
    margin-bottom: 1rem;
}

.wrapper-traveller .left {
    width: 10%;
    background: linear-gradient(to right, #01a9ac, #0779c3);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    color: #fff;
    display: grid;
    align-items: center;
    justify-content: center;
}

.wrapper-traveller .left img {
    border-radius: 5px;
    margin-bottom: 10px;
    width: 60%;
    min-height: 60%;
    max-height: 60%;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
}

.wrapper-traveller .left h4 {
    margin-bottom: 10px;
    font-size: 16px;
    color: white;
}

.wrapper-traveller .left p {
    font-size: 12px;
}

.wrapper-traveller .right {
    width: 100%;
    background: #ffffff;
    padding: 1rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.wrapper-traveller .right .info h3,
.wrapper-traveller .right .projects h3 {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e0e0e0;
    color: #353c4e;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 15px;
}

.wrapper-traveller .right .info_data,
.wrapper-traveller .right .projects_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper-traveller .right .info_data .data h4,
.wrapper-traveller .right .projects_data .data h4 {
    color: #212529;
    margin-bottom: 5px;
    font-size: 14px;
}

.wrapper-traveller .right .info_data .data p,
.wrapper-traveller .right .projects_data .data p {
    font-size: 13px;
    margin-bottom: 10px;
    color: #919aa3;
}

.wrapper-traveller .social_media ul {
    display: grid;
    justify-content: right;
    align-items: center;
}

.wrapper-traveller .social_media ul li svg {
    background: linear-gradient(to right, #01a9ac, #0779c2);
    margin-right: 10px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    display: block;
    width: 30px;
    height: 30px;
    font-size: 14px;
    padding: 0.5rem;
    cursor: pointer;
}

.wrapper-traveller .social_media ul li svg {
    font-size: 18px;
}

.travellerAddPop.MuiBox-root.css-1wnsr1i {
    border: 0;
    border-radius: 20px;
    width: 70% !important;
    overflow-y: scroll;
}

.addEdit_pop_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.addEdit_pop_btn button {
    font-size: 13px !important;
    padding: 0.5rem 1.5rem;
    font-weight: 500;
    font-family: 'Poppins';
}

.travellerAddPop label {
    font-size: 13px;
    color: #949494;
}

.travellerAddPop input {
    font-size: 14px;
}

.travellerAddPop select {
    font-size: 14px;
}

.addTraveller_btn button {
    border: 1px solid #067fc0;
    margin: 1rem;
    font-size: 13px;
}

.addTraveller_btn button svg {
    fill: #067fc0;
    font-size: 16px;
    margin: 0 5px;
}

.hotel_payment .MuiBox-root.css-1wnsr1i {
    border: 0 !important;
    border-radius: 0px !important;
    width: 100% !important;
    overflow-y: initial !important;
    position: relative !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
}

.MuiAccordionDetails-root.traveller_accordian_detail {
    display: block !important;
    padding: 0 !important;
}

.reset_password label {
    font-size: 17px !important;
}

.reset_password input {
    width: 80%;
    background: #ecf1fc;
    margin: 0;
    border-radius: 8px;
    margin: auto;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.reset_password button {
    margin-left: 0;
}

.continue_guest {
    color: white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    padding: 3rem;
    text-align: center;
}

.blur_bg {
    position: fixed;
    background: #0000008a;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 999999999;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.continue_guest button {
    border: white;
    margin: 0 !important;
    padding: 13px 30px !important;
}

span.sign_in_bt {
    color: #fff;
    background: #0041F2;
    font-size: 12px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    border-radius: 38px;
}

.guest_field ul li {
    display: initial;
}

span.pas_td {
    font-size: 13px;
    font-weight: 600;
}

.guest_field ul li a {
    padding: 0rem 0.5rem;
    margin: 0px 0 0 0.5rem;
    border-radius: 50%;
    box-shadow: 0px 0px 3px 2px #6d6d6d2e;
}

.guest_field ul {
    line-height: 2.5;
}

.guest_field {
    position: absolute;
    background: #004d83;
    border-radius: 10px;
    z-index: 999;
    width: 100%;
    padding: 0 1rem;
    padding-top: 1rem;
    left: 0;
    right: 0;
    display: inline-table;
}

/* .room_guest_select {
    position: relative;
} */
.guest_field .css-1s2u09g-control {
    margin-bottom: 0.5rem;
}

ul.chlid_agelist li {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

ul.chlid_agelist li span {
    width: 20%;
}

ul.chlid_agelist li {
    width: 60%;
}

ul.total_room_guest li {
    display: inline-block;
    margin: 0 10px;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0rem;
}

.td_badge {
    margin-left: 0.2rem;
    background: #0543e9;
    padding: 0 6px;
    border-radius: 50px;
    color: #fff;
}

.guest_field select {
    background: #2c3e50;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    font-size: 11px;
    text-transform: capitalize;
    color: #fff;
    padding: 5px 10px;
    display: flex;
    margin-bottom: 0.5rem;
}

ul.total_room_guest li span {
    color: #0543e9;
}

.child_count_head {
    color: #0543e9;
    text-align: center;
}

.room_guest_select {
    position: relative;
}

.hotel_search label.form-label {
    font-size: 13px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0 !important;
    width: 100%;
    color: hsl(0deg 0% 35%);
    text-transform: capitalize;
    letter-spacing: 1px;
}

.hotel_search .search_btn_banner {
    background: hsl(224deg 100% 47%) !important;
    color: #fff !important;
    padding: 8px 50px !important;
    border-radius: 30px !important;
    text-transform: capitalize;
    float: right;
}

.hotel_search .search_btn_banner span {
    color: #fff !important;
    margin-right: 10px;
}

.banner_box.hotel_search .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-mhc70k-MuiGrid-root {
    align-items: center;
}

.room_guest_btns {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    margin: 1rem 0;
}

.room_guest_btns button {
    background: none;
    color: #ffffff;
    font-size: 12px;
    border: 1px solid #ffffff;
    width: 40%;
}

.active_badge {
    background: #000;
    color: #fff !important;
}

.loaderGif {
    position: fixed;
    z-index: 99999999999999999999;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.loaderGif img {
    width: 12rem;
    min-height: 12rem;
    max-height: 12rem;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 50%;
    object-fit: contain;
    background: #fff;
}

.search_flight_gif .loaderGif {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000b0;
}

.search_flight_gif .loaderGif img {
    width: 25vh;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    min-height: 25vh;
    max-height: 25vh;
    object-fit: contain;
    background: #cceaf7;
    border-radius: 50%;
}

.home_flight_search .banner_box {
    padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}

.confirm_ticket_pop button.bookingBtn.Mui-disabled.Mui-disabled {
    color: rgb(255 255 255 / 57%) !important;
    opacity: 80%;
    background: #6c757d !important;
}

.confirm_ticket_pop button.bookingBtn.Mui-disabled.Mui-disabled:after {
    bottom: -2.8rem;
    color: red;
    content: "*Kindly add traveller and details to proceed further";
    font-size: 10px;
    position: absolute;
}

.user_profile {
    padding: 3rem 0 5rem 0;
}

.user_detail_area {
    font-size: 15px;
    font-weight: 600;
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    font-family: 'Poppins' !important;
    margin-left: 0;
    margin-right: 0;

}

.user_menu {
    margin-left: 1rem;
}

.user_menu span.pr-2 {
    color: #2c3e50;
    font-size: 12px;
    font-weight: 600;
    margin-left: 0.2rem;
    background: -webkit-linear-gradient(#12122e, #208eda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.user_profile_page .css-h0q0iv-MuiButtonBase-root-MuiTab-root>.MuiTab-iconWrapper {
    margin-right: 8px;
    fill: #1d8ddc;
}

.user_profile_sec .form-group {
    margin: 0.5rem 0;
}

.continue_guest ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.continue_guest ul li {
    color: #fff;
    padding: 0 0.5rem;
}

a.book_login {
    margin-top: 0 !important;
    color: #fff !important;
    background: #0543e9;
    padding: 10px 25px !important;
    border-radius: 30px;
    font-weight: 400;
}

.hotel_trip h4 {
    font-size: 14px;
    color: #fff;
}

.hotel_loc {
    position: relative;
    left: -0.5rem;
}


.hotel_booking_details_sec {
    padding: 3rem 0;
    display: flex;
    justify-content: space-evenly;
}

.hotel_booking_details_sec .hotel_search_field_box h6 {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
    text-align: center;
    color: #2c3e50;
}

.hotel_booking_details_sec .hotel_search_field_box {
    text-align: center;
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #0000001f;
    display: grid;
}

.hotel_booking_details_sec .hotel_search_field_result svg {
    fill: #faaf00;
}

.hotel_booking_details_sec .hotel_search_field_result span {
    color: #263a4a;
    font-size: 14px;
}

.trip_rules ul li {
    font-size: 12px;
    font-weight: 500;
    background: #ffffff;
    padding: 5px;
    color: #000;
    border-radius: 5px;
    margin-bottom: 0.2rem;
    letter-spacing: 0.5px;
}

.trip_rules ul {
    margin: 1rem 0 !important;
    text-align: center;
    justify-content: space-around;
}

.trip_rules table {
    width: 100%;
    border: 1px solid #cfcfcf;
    font-size: 12px;
    font-weight: 400;
    line-height: 2;
    background: white;
}

.trip_rules {
    font-size: 13px;
    font-weight: 400;
    padding: 1rem;
}

.trip_rules table td {
    border: 1px solid #bdbdbd78;
    padding: 3px;
}

.trip_rules table tr {
    border: 1px solid #bdbdbd78;
    padding: 3px;
}

li.travel_hours::before {
    display: none;
}

li.travel_hours span {
    background: #3d3d3deb;
    padding: 5px 10px;
    font-size: 10px;
    color: #fff;
    border-radius: 16px;
}

.guest_room_list {
    position: relative;
}

.guest_room_list button {
    color: #0543e9;
    font-size: 12px;
    text-transform: capitalize;
    height: -moz-fit-content;
    height: fit-content;
}

.guest_room_list {
    position: relative;
    border-inline: 4px solid #ffc309;
    padding: 0.5rem;
    background: white;
    margin-bottom: 1rem;
    border-radius: 10px;
}

.trip-in select {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: inherit;
}

.cancelPop {
    text-align: center;
}

.cancelPop label {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0.7rem;
}

.otp_field {
    justify-content: center;
    margin: 1rem 0;
}

.otp_field input {
    border: 1px solid #d9d9d9;
}

.cancelPop button {
    font-size: 11px;
    margin-top: 1rem;
    background: #096dd9;
}

.ticket_booking_count span {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #0747b5;
}

.ticket_booking_count p {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-top: 0.2rem;
}

.ticket_booking_count {
    text-align: center;
    background: #ededed;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    position: absolute;
    z-index: 9999999999999999999999999;
    top: 1rem;
    right: 1rem;
}

.pnr_cancel_sec {
    display: flex;
    justify-content: center;
    height: 70vh;
    align-items: center;
    background-image: url(../../asset/images/flight-bg.avif);
    background-size: cover;
    background-repeat: no-repeat;
}

.pnr_cancel_card {
    display: block;
    background: #ffffffbd;
    padding: 3rem 3rem;
    border-radius: 10px;
    width: fit-content;
}

.pnr_cancel_card label {
    font-size: 14px;
    font-weight: 500;
    color: #3d3d3d;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
}

.pnr_cancel_card input {
    font-size: 13px;
}

.pnr_cancel_card small {
    font-size: 9px;
    color: #000000 !important;
}

.pnr_cancel_card button {
    width: 100%;
    margin-top: 1rem;
    font-size: 14px;
    padding: 0.6rem 0;
    background: #0090fb;
    color: #fff;
    border: none;
}

.toaster_card {
    position: fixed;
    z-index: 9999999999999999999999999999999999 !important;
    top: 3rem;
    right: 1rem;
    background: #fff !important;
}

.toaster_card button.btn-close {
    display: none;
}

.toaster_card .toast-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: #ffffff;
    background-color: rgb(0 65 241);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2 {
    z-index: 9999999 !important;
}

.hotel_search .DateRangePickerInput__withBorder {
    border-radius: 2px !important;
    border: 1px solid #dbdbdb !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-evenly !important;
    border: none !important;
}

.hotel_search .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
}

.hotel_search .DateInput_input {
    font-size: 14px;
    line-height: 24px;
    color: #212529 !important;
    background-color: #fff;
    width: 100%;
    padding: 5px 10px;
    border: none !important;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0;
    font-weight: 600;
    font-family: 'Poppins';
    opacity: 100%;
    border-bottom: 1px solid #cccccc40 !important;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #0543e9;
    height: 13px;
    width: 3rem;
}

.hotel_search .DateInput_input::placeholder {
    color: #212529 !important;
    font-weight: 500;
}

.re_direction_model {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2c3e50;
}

.flight_con {
    text-align: center;
}

.flight_con p {
    color: #fff;
    margin-bottom: 1rem;
}

.flight_con button {
    background: #f6c220 !important;
    color: #fff !important;
    padding: 10px 30px !important;
    border-radius: 10px !important;
    height: 50px;
}

.dialoque_model .modal {
    display: block;
}

.dialoque_model button.close {
    border: 1px solid #b1b1b1;
}

.dialoque_model p {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin: 2rem 0;
}

.fail_model p {
    font-size: 14px;
    font-weight: 400;
    color: #525252;
    text-align: center;
    margin: 1rem 0;
}

span.bld_txt {
    font-weight: 700;
    color: black;
}

.flight_trip_sec .DateInput.DateInput_1 {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: inherit;
    width: 100%;
}

.flight_trip_sec .SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border-bottom: none !important;
    width: 100%;
    border: none !important;
}

.flight_trip_sec .SingleDatePicker.SingleDatePicker_1 input {
    border-bottom: none !important;
    font-size: 15px;
}

.flight_trip_sec .SingleDatePicker {
    position: relative;
    display: inline-block;
    width: 100% !important;
}

.nearhotel_card_img img {
    height: 100%;
    width: 100%;
    max-height: 220px;
    min-height: 220px;
    object-fit: cover;
}

.nearhotel_card_img_grid img {
    height: 100%;
    width: 100%;
    max-height: 250px;
    min-height: 250px;
    object-fit: cover;
}

.hotel_review .css-ryrseu-MuiRating-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    font-size: 16px;
    color: #faaf00;
    cursor: pointer;
    text-align: left;
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
}

.model_filter .form-group {
    display: flex !important;
    justify-content: space-evenly !important;
    width: 100%;
}

.model_filter {
    overflow-y: scroll;
}

.booking_guest_details_model .modal-dialog {
    max-width: 85%;
    margin: 1.75rem auto;
}

.MuiButton-root.Mui-disabled {
    background: #0000007a !important;
    color: #d7d7d7 !important;
}
.addGuestModel label {
    font-size: 13px;
    line-height: 3;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #727272;
}

.add_btn-group {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.add_btn-group button {
    background: #0039d3;
    color: #fff;
}

.addGuestModel .form-group {
    margin-bottom: 1rem;
}

.addGuestModel .modal-title.h4 {
    font-size: 15px;
    text-align: center !important;
    width: 100%;
    color: #4a4a4a;
    text-transform: capitalize;
}

.no_result_found {
    width: 100%;
    text-align: center;
    height: 60vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.no_result_found h2 {
    align-items: center;
    font-weight: 600;
    font-size: 27px;
}

.booking_model_pop .modal-dialog {
    max-width: 95% !important;
}

ul.fecility_room li {
    font-size: 10px;
    font-weight: 500;
    color: #2a2a2a;
    line-height: 2;
    list-style: devanagari;
}

.booking_model_pop .hotel_card_con {
    font-size: 12px;
    color: #000000;
    margin-bottom: 0.5rem;
    font-weight: 500;
    text-transform: capitalize;
    padding: 0;
}

.row.card_fec {
    padding: 0 0.5rem;
}

.hotel_cancel_model form {
    text-align: center;
    line-height: 4;
}

.modal-title.h4 {
    font-size: 15px;
    text-transform: capitalize;
    letter-spacing: 0.1px;
}

.tost_red {
    background: #fb1100;
    color: #000;
}

.hotel_scn_cancel {
    display: flex;
    justify-content: center;
    height: 70vh;
    align-items: center;
    background-image: url(../../asset/hotel-booking.jpg) !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner_box.hotel_search {
    padding: 1rem 3rem;
}

.banner_box.hotel_search label {
    margin-bottom: 5px !important;
}

ul.total_room_guest {
    margin-top: 0.5rem !important;
    padding-top: 0.5rem !important;
    border-top: 1px solid #0000001f;
}

.hotel_list_header {
    margin-bottom: 0.5rem;
    padding: 0;
    padding-left: 5px;
}

.hotel_list .popular_hotel_card {
    border: 1px;
    border-color: rgb(246 246 246) !important;
    margin-top: 1rem;
    border-radius: 10px;
}

.gray-border {
    border: 1px;
    border-color: rgb(246 246 246) !important;
}

.hotel_fecility_sec {
    margin: 2rem 0;
}

.hotel_fecility_sec ul li {
    font-size: 13px;
    line-height: 30px;
    list-style: auto;
    margin-left: 1rem;
    font-weight: 500;
}

.model_filter label {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
}

.model_filter h5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: #f3f3f3;
    text-align: center;
    color: #000;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 13px;
}

.model_filter input {
    border: 1px solid #00000014;
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.model_filter select {
    border: 1px solid #00000014;
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.model_filter button {
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    color: #000;
}

.no_result_found img {
    min-height: 400px;
    max-height: 400px;
    object-fit: none;
}

.alert {
    left: 0;
}

.hotel_fecility_sec h6 {
    margin-bottom: 1rem;
}

.hotel_search_payment_chk {
    display: flex;
    justify-content: flex-start;
    padding: 1rem 0;
}

.hotel_search_payment_chk h6 {
    font-size: 16px;
    font-weight: 400;
    color: #67747c;
}
.hotel_search_payment_chk span {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-left: 0.5rem;
}

.hotel_search_payment_chk div div {
    background: #fff;
    padding: 0;
    margin-top: 0.5rem;
}

.hotel_search_payment_chk div {
    padding: 1rem;
}

.pament_btn_sec {
    position: relative;
    padding-bottom: 2rem;
}

span.alert_hot_pay {
    position: absolute;
    bottom: 0;
    color: red;
    width: 100%;
    display: block;
}

.d-nn {
    display: none;
}

.hotel_pagination {
    width: 100%;
    margin-top: 4rem !important;
    display: flex;
    justify-content: center;
}

.guest_room_list h5 {
    font-size: 11px;
    color: #000000;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    background: #f6c220;
    padding: 4px 0;
    border-radius: 10px;
}

.guest_details_table {
    margin-bottom: 1rem;
}

.guest_details_table table {
    text-align: center;
}

.guest_details_table table>:not(:first-child) {
    border-top: 0px solid currentColor;
}

.hotel-search_form .banner_box.hotel_search {
    padding: 3rem 1rem;
    background: #f0f4f7;
}
.hotel-search_form .banner_box.hotel_search .ant-picker-input input {
    font-size: 12px !important;
}
.hotel-search_form .banner_box.hotel_search div#react-select-2-placeholder {
    font-size: 14px;
}
.hotel-search_form .banner_box.hotel_search .select_guest_search span {
    font-size: 12px;
}
a.back_home {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: 1px solid #b9b9b9;
    color: #fff !important;
    padding: 0.4rem 1rem;
    font-size: 12px;
}

a.back_home svg {
    font-size: 18px;
    margin-right: 0.5rem;
    margin-top: -3px;
}

.hori_card_con h3 {
    min-height: fit-content;
}

.hotel_images img {
    width: 100%;
    min-height: 270px;
    max-height: 270px;
    object-fit: cover;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    background: #fff;
    padding: 0.5rem;
    box-shadow: 0px 0px 18px 0px #00000040;
}

.hotel_images {
    margin-top: 3rem;
}

.result_hotel_details_sec {
    background: #f3f3f3;
    padding: 2rem;
    text-align: justify;
    margin: 2rem 0;
}

.result_hotel_details_sec p br {
    display: none !important;
}

.result_hotel_details_sec p p br {
    display: block !important;
}

.result_hotel_details_sec b {
    font-size: 15px !important;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #000;
    background: -webkit-linear-gradient(#12122e, #208eda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.result_hotel_details_sec p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 5px;
}

.redBox {
    border: 1px solid #ff110073;
}

.flight_root {
    border: 1px solid #cbcbcbb0;
    background: #f3f3f3;
}

.flight_root .destinamtion_flight {
    background: #f3f3f3;
}

.connecting_waiting_time {
    font-size: 13px;
    display: flex;
    width: 100%;
    justify-content: center;
    background: linear-gradient(to right, #ff9800, #198ad8);
    color: #fff;
    align-items: center;
    letter-spacing: 2px;
    padding: 3px 0;
    font-weight: 600;
}

.connecting_waiting_time svg {
    margin-right: 0.5rem;
}

span.flight_no {
    font-size: 15px;
    font-weight: 700;
    color: #000000;
    padding: 0.5rem;
    border-left: 2px solid #ff980099;
}

/* .experience_slider .owl-nav.disabled {
    display: none !important;
} */
.experience_slider.testimonial_slider .owl-nav.disabled {
    display: none !important;
}


span.flight_number {
    font-size: 11px;
    font-weight: 500;
    font-family: 'Poppins';
    letter-spacing: 1px;
}

img.floght_single_logo {
    margin-bottom: 0.2rem;
}

.guest_or_login_check .blur_bg {
    position: fixed;
    background: #0000008a;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.select_flight .flightname {
    width: auto;
}

.history-tl-container .flight_roots {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.history-tl-container span.totel_stops {
    display: flex;
    width: auto;
    justify-content: center;
    border: 1px solid #4c4c4c36;
    color: #4c4c4c;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-bottom: 1rem;
    padding: 5px 0;
    border-radius: 17px;
    word-spacing: 1px;
    letter-spacing: 1px;
    background: none;
}

span.stop_qty {
    background: #084ec7;
    padding: 0 8px;
    margin-left: 0.5rem;
    border-radius: 50%;
    color: #fff;
}

.flight_trip_root {
    margin-bottom: 2rem;
}

.flight_roots span.flight_when {
    margin-left: 0.2rem;
}

button:focus-visible {
    outline: 2px solid #000 !important;
    outline-offset: 5px !important;
}

.form-control:focus-visible {
    outline: red;
}

a:focus-visible {
    outline: 2px solid #000 !important;
    outline-offset: 5px !important;
}

.profileStyleModel {
    width: 60%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: white;
    display: flex;
    align-items: center;
    height: fit-content;
    padding: 2rem;
}

.trip-in .ant-picker {
    width: 100%;
    border: none;
}

.trip-in .ant-picker-input input {
    border-bottom: none !important;
}

.hotel_search .css-6vrbi4-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color:#f0f4f7;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 0;
    outline: none;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    border: none;
    padding: 0px 0px;
}

.hotelSearch_grid .css-319lph-ValueContainer {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: grid;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2px 8px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background: #f0f4f7;
}

.borderBottom {
    border-bottom: 1px solid #ddd;
}

.hotelSearch_grid .css-6vrbi4-control:hover {
    outline: none !important;
    border: none !important;
}

.hotelSearch_grid .css-6vrbi4-control:active {
    outline: none !important;
    border: none !important;
}

.hotelSearch_grid .css-1c9z772-control:active {
    outline: none !important;
    border: none !important;
}

.hotel_search .ant-picker {
    box-sizing: border-box;
    margin: 0;
    width: 90%;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    padding: 0 11px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #f0f4f7;
    border: none;
    border-radius: 2px;
    transition: border 0.3s, box-shadow 0.3s;
}

.hotelSearch_grid .css-1c9z772-control {
    padding: 0 !important;
}

.add_guest_sec {
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin-top: 0.5rem;
}

.booking_values {
    border-inline: 5px solid #faaf00;
    border-radius: 10px;
}

.grid_shape button {
    color: #fff;
}

.grid_shape ul li:nth-child(2) svg {
    color: #ffffff;
}

.near_plcess_details {
    display: flex;
}

.near_plcess_details button {
    /* border-top: none !important;
    border-right: none !important; */
}

.addGuest .wrapper-traveller .right {
    width: 100%;
    background: #ffffff;
    padding: 1rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
}

.card p {
    font-size: 11px !important;
    color: #9b9b9b !important;
    margin-top: 0.5rem !important;
    z-index: 1000;
    transition: color 0.3s ease-out;
    letter-spacing: 1px;
}

header {
    z-index: 0 !important;
}

.home_flight_search h3 {
    color: #fff;
    z-index: 9 !important;
}

.room_review_inner_con {
    width: 90%;
}

.review_user_name {
    margin-bottom: 0.5rem;
}

.review_user_name h6 {
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    color: #1976d2;
    letter-spacing: 1px;
}

.edit_update_review button {
    border: none;
    background: none;
    color: #1976d2b3;
    margin-left: 1rem;
    padding: 0;
}

.edit_update_review {
    margin-top: 0.5rem;
    text-align: right;
}

.edit_update_review button svg {
    font-size: 26px;
    color: #1976d2;
    border: 1px solid #1976d2;
    padding: 4px;
    border-radius: 50%;
}

/* .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgb(25 118 210) !important;
    border: 1px solid #1976d2;
}
.MuiButton-root:hover {
    color: #fff !important;
    text-decoration: none;
    background-color: rgb(5 67 233) !important;
} */
.hotel_not_found {
    width: 100%;
    display: inline-grid;
    justify-content: center;
    height: 40vh;
    align-items: center;
}

.hotel_not_found img {
    width: 70%;
    margin: auto;
}

.hotel_not_found h3 {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
}

span.alert_hot_adultcheck {
    position: absolute;
    color: #f00;
}

.session_timer {
    position: relative;
    background: hsl(224deg 100% 47%) !important;
    z-index: 9999999999999;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 10px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.session_timer p {
    color: #fff;
}

.session_timer svg {
    color: #fff;
    font-size: 21px;
    margin-right: 0.2rem;
}

.hotel_confirm_pas_det .info_data {
    display: block !important;
    text-align: left !important;
    width: 100%;
}

.hotel_confirm_pas_det .info_data .data {
    width: 45% !important;
    background: #f9f9f9;
    padding: 0.5rem;
    border-inline: 3px solid #f6c220;
    border-radius: 10px;
    margin: auto;
    float: left;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
}

/* .details_booking_img img {
    width: 100%;
    min-height: 50vh;
    max-height: 50vh;
    object-fit: cover;
    box-shadow: 0px 0px 25px 0px #00000061;
}
.details_booking_img {
    margin-top: 3rem;
} */
.home_search_box_sec .trip_sec {
    padding: 0;
    background: no-repeat;
}

.home_search_box_sec .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #ffffff !important;
}

.home_search_box_sec .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected span {
    color: #ffffff !important;
}

.home_search_box_sec .css-aym9vq-MuiButtonBase-root-MuiTab-root svg {
    color: #ffffff !important;
}

.home_search_box_sec .css-aym9vq-MuiButtonBase-root-MuiTab-root {
    padding: 0.5rem 3rem !important;
    background: #0000006b !important;
    min-height: -moz-fit-content !important;
    min-height: fit-content !important;
}

.home_search_box_sec .makeStyles-tabinner-22 {
    padding: 2rem 3rem !important;
    background: #fff !important;
    border-radius: 50px !important;
    border-inline: 5px solid #ff9800 !important;
}

.flight_trip_sec .css-mhc70k-MuiGrid-root {
    justify-content: center !important;
}

.travellerAddPop .form-group {
    width: 90%;
    margin-bottom: 0.5rem;
}

.home_search_box_sec .css-1gsv261 {
    border-bottom: none;
    border-color: rgb(251 21 21 / 12%);
}

.required-Field {
    border-radius: 2px;
    border: 2px #dc3545 solid !important;
}

.required-Field:focus {
    border-radius: 2px;
    border: 2px #dc3545 solid !important;
    box-shadow: 0 0 0 0.2rem rgb(241 75 75 / 25%) !important;
}

.Dropdown-required-Field {
    border-radius: 2px;
    border: 1px #dc3545 solid !important;
}


.home_search_box_sec .css-1tnnsql {
    color: rgb(255 255 255) !important;
    padding: 0.5rem 3rem !important;
    background: linear-gradient(to right, #a7d5f2, #198ad8) !important;
    border-radius: 10px 10px 0px 0px !important;
    min-height: -moz-fit-content !important;
    min-height: fit-content !important;
}

.home_search_box_sec .css-ttwr4n {
    position: absolute !important;
    height: 5px !important;
    bottom: 0 !important;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    background-color: #ff9800 !important;
}

.home_search_box_sec .tb_bnl .Mui-selected span {
    color: #0041F2 !important;
}

.home_search_box_sec .css-isbt42 {
    justify-content: center !important;
}

.flight_not_found {
    display: grid;
    align-items: center;
    justify-content: center;
}

.flight_not_found img {
    width: 200px;
    min-height: 200px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 20px 0px #00000040;
}

.flight_not_found a {
    font-size: 13px;
    border: 1px solid;
    padding: 5px 1rem;
    width: fit-content;
    margin: auto;
    margin-top: 2rem;
}

.flight_not_found h3 {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
}

.hotel_not_found a {
    font-size: 13px;
    border: 1px solid;
    padding: 5px 1rem;
    width: fit-content;
    margin: auto;
    margin-top: 2rem;
}

.login_form.login_user label {
    position: relative !important;
    font-size: 13px !important;
    margin-left: 0 !important;
}

.login_form.login_user input {
    margin: 0;
    line-height: 2;
    margin-bottom: 1.5rem;
    font-size: 14px;
}

.popular_detail_con.hotel_list.hotel_result button.hotel_btn {
    margin-bottom: 2rem;
}

.guest_room_list svg {
    color: #f44336c9;
}

.contact {
    padding: 130px 0;
    padding-top: 80px;
}

.contact .heading h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    padding: 0;

}

.contact .heading h2 span {
    color: #0b7dc8;
}

.contact .heading p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    color: #999999;
    margin: 20px 0 60px;
    padding: 0;
}

.contact .form-control {
    padding: 20px;
    font-size: 13px;
    margin-bottom: 2rem;
    background: #f9f9f9;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px #00000026;
}

.contact button.btn {
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    background: #ff9100;
    color: #ffffff;
}

.contact .title h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.contact .title p {
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 1.6;
    margin: 0 0 40px;
}

.contact .content .info {
    margin-top: 30px;
    display: flex;
    align-items: center;
}

.contact .content .info i {
    font-size: 30px;
    padding: 0;
    margin: 0;
    color: #02434b;
    margin-right: 20px;
    text-align: center;
    width: 20px;
}

.contact .content .info h4 {
    font-size: 13px;
    line-height: 1.4;
}

.contact .content .info h4 span {
    font-size: 13px;
    font-weight: 300;
    color: #999999;
}

.info svg {
    font-size: 40px;
    color: #087ac4;
    margin-right: 1rem;
}

.about-section {
    position: relative;
    padding: 120px 0px;
}

.about-section .content-column {
    position: relative;
    margin-bottom: 40px;
}

.about-section .content-column .inner-column {
    position: relative;
    padding-top: 50px;
    padding-right: 100px;
}

.about-section .content-column .text {
    position: relative;
    color: #777777;
    font-size: 15px;
    line-height: 2em;
    margin-bottom: 40px;
}

.about-section .content-column .email {
    position: relative;
    color: #252525;
    font-weight: 700;
    margin-bottom: 50px;
}

.about-section .image-column {
    position: relative;
    margin-bottom: 50px;
}

.about-section .image-column .inner-column {
    position: relative;
    padding: 40px 40px 0px 0px;
    margin-left: 50px;
}

.about-section .image-column .inner-column:after {
    position: absolute;
    content: '';
    right: 0px;
    top: 0px;
    left: 40px;
    bottom: 100px;
    z-index: -1;
    border: 2px solid #ff9800;
}

.about-section .image-column .inner-column .image {
    position: relative;
}

.about-section .image-column .inner-column .image:before {
    position: absolute;
    content: '';
    left: -50px;
    bottom: -50px;
    width: 299px;
    height: 299px;
    /* background:url(img/pattern-2.png) no-repeat; */
}

.about-section .image-column .inner-column .image img {
    position: relative;
    width: 100%;
    display: block;
}

.about-section .image-column .inner-column .image .overlay-box {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    background: #00000069;
    padding: 1rem;
}

.about-section .image-column .inner-column .image .overlay-box .year-box {
    position: relative;
    color: #ffffff;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.4em;
    padding-left: 125px;
}

.about-section .image-column .inner-column .image .overlay-box .year-box .number {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 110px;
    height: 110px;
    color: #ff9800;
    font-size: 68px;
    font-weight: 700;
    line-height: 105px;
    text-align: center;
    background-color: #ffffff;
    border: 3px solid #ff9800;
}

.about-section .btn-style-three:before {
    position: absolute;
    content: '';
    left: 10px;
    top: 10px;
    z-index: -1;
    right: -10px;
    bottom: -10px;
    background: url(https://i.ibb.co/DKn55Qz/pattern-1.jpg) repeat;
}

.about-section .btn-style-three:hover {
    color: #ffffff;
    background: #ff9800;
}

.about-section .btn-style-three {
    position: relative;
    line-height: 24px;
    color: #252525;
    font-size: 15px;
    font-weight: 700;
    background: none;
    display: inline-block;
    padding: 11px 40px;
    background-color: #ffffff;
    text-transform: capitalize;
    border: 2px solid #ff9800;
    font-family: 'Arimo', sans-serif;
}

.about-section .sec-title2 {
    color: #fff;
}

.about-section .sec-title {
    position: relative;
    padding-bottom: 40px;
}

.about-section .sec-title .title {
    position: relative;
    color: #ff9800;
    font-size: 18px;
    font-weight: 700;
    padding-right: 50px;
    margin-bottom: 15px;
    display: inline-block;
    text-transform: capitalize;
}

.about-section .sec-title .title:before {
    position: absolute;
    content: '';
    right: 0px;
    bottom: 7px;
    width: 40px;
    height: 1px;
    background-color: #bbbbbb;
}

section.about-section h2 {
    font-size: 32px;
    line-height: 1.5;
    background: -webkit-linear-gradient(#097bc6, #ff9800);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer_content li a {
    color: #ffffff9e !important;
}

.footer_content li a:hover {
    color: #fff !important;
}

/* .home_search_box_sec .jss21{
    padding: 2rem 3rem !important;
    background: #fff !important;
    border-radius: 50px !important;
    border-inline: 5px solid #ff9800 !important;
} */


.footer_contact_form_sec {
    padding: 100px 0px;
    background-color: #fff;
}

.left_foo_con_sec {
    box-shadow: 0 0px 30px 0 rgb(0 0 0 / 25%);
    padding: 30px;
    width: 90%;
    border-radius: 10px;
}

.single_persion_con {
    box-shadow: 0 0px 30px 0 rgb(0 0 0 / 25%);
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    border-inline: 5px solid #ff9800;
}

.con_foo_input_wrapper input,
.con_foo_input_wrapper select,
.con_foo_input_wrapper textarea {
    width: 100%;
    box-shadow: 0 0px 10px 0 rgb(0 0 0 / 11%);
    border: none;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 14px;
    margin-top: 0.5rem;
    border-radius: 10px;
}


.con_foo_input_wrapper input::placeholder,
.con_foo_input_wrapper select::placeholder,
.con_foo_input_wrapper textarea::placeholder {
    font-weight: 600;

}

.con_foo_input_wrapper .file_type {
    height: 47px;
}

.con_foo_input_wrapper label {
    font-weight: 600;
    font-size: 14px;
}

.con_foo_input_wrapper textarea {
    height: 100px;
}

.con_foo_input_wrapper {
    margin-bottom: 20px;
}

.left_foo_con_sec h4 {
    font-weight: 700;
    margin-bottom: 20px;
}

.single_persion_con h4 {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.single_persion_con p {
    font-size: 13px;
    color: #777676;
    font-family: 'Poppins', sans-serif;
}

.single_persion_con ul li {
    color: #484848;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
}

.single_persion_con ul li {
    list-style: upper-roman;
    margin-left: 1rem;
    line-height: 2;
}

.single_persion_con ul {
    padding-left: 0;
}

.single_persion_con ul li a i {
    color: #e85516;
    font-size: 18px;
}

.banner_btn {
    border-radius: 50px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    padding: 15px 50px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 20px;
    transition: ease-in all 0.3s;
    background-image: linear-gradient(to right, #dd100e, #ea6117);
}

.left_foo_con_sec h4 {
    text-align: center;
    font-size: 25px;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.wrap_term {
    padding: 3rem 0;
    padding-bottom: 6rem;
}

.container__heading {
    padding: 1rem 0;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

.container__heading>h2 {
    font-size: 26px;
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 1px;
    color: #0c7ec9;
    background: -webkit-linear-gradient(#12122e, #208eda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container__content {
    flex-grow: 1;
    overflow-y: scroll;
    height: 53vh;
    overflow-x: hidden;
    background: #2c3e50;
    padding: 1rem 2rem;
}

.container__nav {
    border-top: 1px solid #ccc;
    text-align: right;
    padding: 2rem 0 1rem;
}

.container__nav>.button {
    background-color: #444499;
    box-shadow: 0rem 0.5rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
    padding: 0.8rem 2rem;
    border-radius: 0.5rem;
    color: #fff;
    text-decoration: none;
    font-size: 0.9rem;
    transition: transform 0.25s, box-shadow 0.25s;
}

.container__nav>.button:hover {
    box-shadow: 0rem 0rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
    transform: translateY(-0.5rem);
}

.container__nav>small {
    color: #777;
    margin-right: 1rem;
}

.container__content p {
    color: #ffffffa6;
    font-size: 13px;
    line-height: 30px;
    margin-bottom: 0.5rem;
    font-weight: 200;
}

.container__content p:hover {
    color: #fff;
}

.term_inner_content h4 {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.container__nav button {
    margin-top: 0 !important;
}

.faq_tab_section {
    padding-top: 2rem;
    padding-bottom: 5rem;
    background: #f5f5f5;
}

.faq_tab_inner button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 500;
    align-items: center;
    padding: 1rem;
}

.faq_content_detail p {
    font-size: 13px;
    text-align: justify;
}

.faq_tab_inner_content {
    box-shadow: 0px 10px 10px 0px #00000026;
}

.faq_content_detail {
    background: #ffffff;
    padding: 1.5rem;
    border-top: 2px solid #ff9800;
}

.faq_tab_inner_content button svg {
    border: 1px solid #0c7ec9;
    font-size: 25px !important;
    color: #0c7ec9;
    border-radius: 50%;
    padding: 3px;
}

.faq_tab_inner_content {
    margin-bottom: 1rem;
}

.faq_tab_inner {
    padding: 2rem 0;
}

.room_review_inner_btn button {
    border: 1px solid #00000030;
    font-size: 13px;
    letter-spacing: 1px;
}

.room_review_inner_btn {
    margin-bottom: 2rem;
}

.hotelSearch_grid {
    display: flex !important;
    justify-content: space-between;
    align-items: flex-start;
}

.popularPlace_img {
    min-height: 230px;
    max-height: 230px;
    object-fit: cover;
}

.select_guest_search span {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    word-spacing: 3px;
}

.coupon_input {
    margin: 1rem 0;
}

.popular_card h5 {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
}

.popular_card {
    cursor: pointer;
}

.popular_detail_page h4 {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
}

.overlay_popular {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #0000002e;
}

.popular_other_places_card {
    position: relative;
}

.popular_other_places_card img {
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
}

.popular_other_places_card .overlay_popular {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #00000069;
    z-index: 1;
}
.profileStyleModel input {
    margin-bottom: 1rem;
}

.profileStyleModel label {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.travellerAddPop {
    position: absolute;
    width: 80%;
    background: #fff;
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    height: fit-content;
    padding: 2rem;
}

.user_profile_icon {
    border: 1px solid #000;
    padding: 3px 5px;
}

.maillinkbtn {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.user_menu button {
    border: 1px solid #0000001c !important;
    padding: 3px 5px;
    box-shadow: 0px 0px 10px 0px #00000047;
    border-radius: 20px;
}

.hotel_payment_check {
    margin-left: 1rem;
}

.user_slider {
    position: relative;
}

.user_login_slider {
    width: 43%;
    position: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    text-align: center;
}

a.back_home.dn-m {
    display: none;
}

.booking_select_tab {
    background: #f0f4f7;
    padding: 2rem 2rem;
}

.subscribe_form input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #00000047;
    border-radius: unset;
    padding: 10px;
}

.subscribe_form {
    position: relative;
    width: 50%;
}

.booking_head h3 {
    font-size: 19px;
    text-align: center;
    margin: 2rem 0 !important;
    font-weight: 600;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#12122e, #208eda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.user_booking_details button {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.user_trip_sec .tab-content .active {
    background: #fff;
    padding: 2rem;
}

ul.user_booking_details li .active {
    color: #2a9aeb !important;
}

ul.user_booking_details li button {
    padding: 1rem 0;
}

.child_pass {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.child_pass button {
    border: none;
    font-size: 11px;
    background: no-repeat;
    color: red;
    font-weight: 500;
    text-decoration: underline;
}

.coupon_apply {
    margin-top: 0.5rem !important;
    margin-left: 0 !important;
}

.hotel_search_submit {
    text-align: center;
}

.flight_pop_total .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(5px, 0px) scale(0.75);
    color: #084ec7;
    display: none;
}

.coupon-card {
    background: linear-gradient(135deg, #294a66, #2196f3);
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    border-radius: 15px;
    box-shadow: 0 10px 10px 0 rgb(0 0 0 / 15%);
    position: relative;
}

.coupon-card .logo {
    width: 80px;
    border-radius: 8px;
    margin-bottom: 20px;

}

.coupon-card h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: #fff;
    text-transform: capitalize;
}

.coupon-card p {
    font-size: 11px;
    color: #fff;
}

.coupon-row {
    display: flex;
    align-items: center;
    margin: 0.5rem auto;
    width: -moz-fit-content;
    width: fit-content;
}

#cpnCode {
    border: 1px dashed #fff;
    padding: 5px;
    border-right: 0;
    font-size: 15px;
    letter-spacing: 1px;
}

#cpnBtn {
    border: 1px solid #fff;
    background: #fff;
    padding: 10px 10px;
    color: #343434;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.coupon-card .circle1,
.circle2 {
    background: #f0fff3;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.coupon-card .circle1 {
    left: -25px;
}

.coupon-card .circle2 {
    right: -25px;
}

.coupon-card {
    margin-bottom: 1.5rem;
}

.couonCodeCopy {
    color: #8bc34a !important;
}

.home_banner_box {
    height: 100vh;
}

.bannercon {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.banner_sec {
    position: relative;
    height: fit-content;
}

.owl-carousel.banner_slider.owl-theme.owl-loaded.owl-drag .owl-nav.disabled {
    display: none !important;
}

.homesearch_box {
    position: absolute;
    top: 55%;
    z-index: 9;
    width: 100%;
}

.banner_sec .booking_select_tab {
    border-radius: 5px;
    box-shadow: 0px 0px 20px 1px #00000063;
    padding: 1rem 0;
}

.bannercon {
    text-transform: capitalize;
}

header.header .wrapper {
    max-width: 100% !important;
    font-size: 14px;
}

.cancel_trip_page a {
    background: hsl(224deg 100% 47%) !important;
    color: #fff !important;
    padding: 0.5rem 1.5rem;
    margin: 0 2rem;
    border-radius: 30px;
    font-size: 14px;
    letter-spacing: 1px;
}

.cancel_trip_page {
    display: flex;
    position: relative;
    height: 40vh;
    align-items: center;
    justify-content: center;
}

.cancelPop.flight_cancel_otp_model {
    padding: 3rem;
}

label {
    font-family: 'Poppins', sans-serif;
}

.apply_coupon_remove {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    border-bottom: 1px solid #0000002e;
    padding-bottom: 0.5rem;
}

.apply_coupon_remove p {
    font-size: 16px;
    background: -webkit-linear-gradient(#12122e, #208eda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    letter-spacing: 1px;
    border-left: 5px solid #ff9800;
    padding: 0.5rem;
}

.apply_coupon_remove button {
    outline: none;
    border: 1px solid #d5d5d5;
    background: no-repeat;
    font-size: 12px;
    padding: 0 10px;
    height: 25px;
    margin: auto;
    margin-right: 0;
    color: #e10000;
}

.hotel-search_form {
    margin-top: 5rem;
}

.navbar .nav-toggle {
    cursor: pointer;
}

.flight_search_btn {
    text-align: center;
}

.trip-in .col-lg-3.col-md-3.col-sm-6.col-12 {
    margin-bottom: 1.5rem;
}

.home_hotel_search .hotel_search {
    padding: 1rem;
}

.trip_select svg {
    color: #0041f2;
}

.model_overFlow_box {
    overflow-y: clip !important;
}

.site_loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.site_loader_img {
    width: 50%;
    margin: auto;
}

.tb_bnl .Mui-selected span {
    color: #000 !important;
}

.makeStyles-topMenu-11 {
    font-size: 15px;
    background: #F1F5F5;
}

.makeStyles-topMenu-11 svg {
    fill: #aaa;
}

.hotelBanner {
    height: 45vh;
    display: flex;
    align-items: end;
    background-repeat: no-repeat;
    background-size: cover;
}

.label-heading {
    color: #67747C !important;
    font-size: 14px !important;
}

.popular-heading {
    color: #333333;
    font-size: 25px;
}

.popular-description {
    color: #666666;
    font-size: 25px;
}

.cardtd {
    color: #333333;
    font-size: 23px;
    text-transform: uppercase !important;
}

.card-desc {
    color: #333333;
    font-size: 16px;
}

.ant-picker-dropdown {
    z-index: 99999;
}

.border-right-1 {
    border-right: 1px solid #e3e3e3 !important;
}

.border-top-gray {
    border-top: 1px solid #e3e3e3 !important;
}

.border-right-gray {
    border-right: 1px solid #e3e3e3 !important;
}

.border-shadow {
    box-shadow: 1px 3px 4px 1px #c0c0c05e;
}

.refine-results {
    font-size: 25px;
    font-weight: 500;
}

.filter-heading {
    font-size: 18px;
    font-weight: 500;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-1 {
    margin-right: 1rem;
}

.pdb-2 {
    padding-bottom: 2rem;
}

.mdb-5 {
    margin-bottom: 0.5rem;
}

.w-50 {
    width: 50% !important;
}

/* .css-1eoe787-MuiSlider-markLabel {
    left: 0% !important;
} */

.home_flight_search .trip-in {
    background-color: #f0f4f7;
}

.trip-in input {
    font-size: 14px;
    font-weight: 600;
}

.trip-in select {
    font-size: 13px;
    font-weight: 600;
}

/* .trip_sec.flight_list_section.home_hotel_search {
    background: #f0f4f7;
} */

.flight_list_search .form-control {
    background: transparent;
}

.flight_list_search .form-select {
    background: transparent;
}

.flight_list_search .css-1s2u09g-control {
    background: transparent;
}

.flight_list_search .ant-picker {
    background: transparent;
}

.flight_list_search {
    width: 100%;
    margin: auto;
}
.trip-in.padd_horz {
    padding: 20px 50px;
}
.homesearch_box .trip-in.padd_horz {
    padding: 0;
}
.flight_image_grid {
    display: flex;
    align-items: center;
}

/* .home_flight_search .trip_sec.flight_list_section.home_hotel_search {
    background: #fff;
}
.home_search_box_sec .css-1q2h7u5 {
    background: #00000047 !important;
    padding: 0 50px !important;
}
.home_search_box_sec .Mui-selected{
    background: #fff  !important;
    color: #000 !important;
}
.home_search_box_sec .css-ttwr4n {
    background-color: #0040f0!important;
    bottom: 0!important;
    height: 2px!important;
    position: absolute!important;
    transition: all .3s cubic-bezier(.4,0,.2,1) 0ms!important;
    width: 100%;
} */

@media (min-width: 576px) {
    .dialog-modal-slider {
        max-width: 800px !important;
        min-height: 800px !important;
        margin: 1.75rem auto;
    }
}

.not-pointer {
    pointer-events: none;
}

.css-1q2h7u5.Mui-selected {
    background: #ffffff !important;
    color: #000 !important;
}

.stop {
    font-size: 11px;
    display: block;
}

.pt-0 {
    padding-top: 0px;
}

.header-divider {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 20px;
    align-items: center;
}

.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-2 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.css-sxo8tj {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #3d3d3d;
    position: absolute;
    white-space: nowrap;
    top: 30px;
    transform: translateX(-50%);
}

.css-6cwnna-MuiSlider-mark {
    left: 0px !important;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #3d3d3d;
    position: absolute;
    white-space: nowrap;
    top: 30px;
    transform: translateX(-50%);
}

/* .ant-picker-input {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: center;
    align-content: center;
    align-items: center;
} */

.footer_content {
    width: 90%;
    margin: auto;
}

.footer_vector {
    position: relative;
}

.vector_image {
    position: absolute;
    right: 0;
    z-index: 0;
}

.app_image {
    border-radius: 8px;
}

.d-Flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

img.location_blue {
    width: 15px;
    height: auto;
}

img.calender_icon {
    width: 18px;
    height: auto;
}

img.guests_icon {
    width: 20px;
    height: auto;
    padding-top: 2px;
}

.cancelBookingBtn {
    background-color: #0041f2;
    border-radius: 90px;
    margin-left: 15px;
}

.cancelbtn {
    color: #fff !important;
    font-size: 12px !important;
    padding: 5px 16px !important;
    font-family: 'Poppins' !important;
}

.dFlexStart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dFlexStartTop {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.anticon svg {
    display: flex;
    align-items: center;
}

.ant-picker-range-separator {
    align-items: center;
    padding: 0 8px;
    line-height: 1;
    padding-right: 15px;
}

.ant-picker-input input {
    color: #000;
    font-weight: 600;
}

.css-14el2xx-placeholder {
    color: hsl(0deg 0% 0%) !important;
    font-weight: 500 !important;
}

h5.select_type {
    font-size: 11px;
}

.margin_right {
    margin-right: 10px;
}

.css-6j8wv5-Input {
    margin: 0 !important;
    padding-top: 0 !important;
}

.home_hotel_search_content .select_guest_search span {
    font-size: 14px !important;
}

.home_hotel_search_content .ant-picker-input input {
    font-size: 13px !important;
}

.home_hotel_search_content .ant-picker-range-separator {
    padding-right: 5px;
}

h1.banner_title {
    padding: 20px 0;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 600;
}

.pb-20 {
    padding-bottom: 20px;
}

button.sortBy_btn {
    text-transform: capitalize;
    padding-right: 8px !important;
    font-size: 13px;
}

.grid_shape ul {
    display: flex;
    align-items: center;
}

.grid_shape ul li button {
    min-width: 40px !important;
}

.popular_hotel_card:hover .nearhotel_card_img_grid img {
    transform: scale(1.05);
    transition: 0.5s ease-in-out all;
}

.popular_hotel_card:hover {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.padd-0{
    padding: 0 !important;
}
.hotel_card_list_contentbox {
    position: relative;
    background-color: #f0f4f7;
}
h6.room_price_list {
    font-size: 21px;
    font-weight: 600;
}
.hotel_card_list_btn {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
}
.hotel_btn_info{
    color:#8f8270 !important;
}

.payment_form_head h6.infor_title {
    margin-bottom: 1.5rem;
    margin-top: 2rem;
}
.facility_space {
    padding-top: 40px;
}
.facility_list_icon img {
    width: 20px;
    height: auto;
    z-index: 1;
}
.facility_list_icon {
    position: relative;
    z-index: 0;
}
.facility_list h6 {
    font-size: 13px;
    line-height: 40px;
    color: #616161;
    padding-left: 5px;
    font-weight: 500;
}
.facility_list_icon:before {
    content: "";
    position: absolute;
    left: -10px;
    width: 25px;
    top: -3px;
    height: 25px;
    background: #eeb669;
    z-index: -1;
    border-radius: 50%;
}
.facility_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
}
.hotel_payment_head.hotelName {
    background-color: #fff7e1;
    padding: 30px 20px;
    margin-bottom: 30px;
}
.room_details_grid {
    margin-right: 50px;
}
.flight_pop_contentent.paddTop-0 {
    padding-top: 0;
}
span.pay_login a {
    color: #0543e9 !important;
    text-transform: uppercase;
}
.mbl-padding {
    padding-bottom: 20px;
}
.home_search_box_sec .flight_trip_sec {
    padding: 0 30px;
}
.flight_date_picker span.ant-picker-suffix {
    display: none;
}
.border_bottom {
    border-bottom: 1px solid #ccc;
}
span.anticon.anticon-calendar {
    display: none;
}
.Sub_page_banner_overlay {
    position: absolute;
    background-color: #00000024;
    width: 100%;
    height: 100%;
}
.sub_banner {
    position: relative;
}
.hotelBanner h1 {
    z-index: 9;
}
.mobileProfile img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
    box-shadow: 0px 0px 8px #00000061;
}
.user_profile_icon_btn {
    padding: 0;
    display: contents;
}
.mobile_nav_icon {
    color: #235ca6;
}
.mobile_nav_bar{
    background-color: #00000096;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -100%;
    transition: 850ms;
}
.backgroundStyle {
    background-color: #011438;
    width: 80vw;
    padding-top: 20px;
}
.mobile_nav_bar.active{
    left: 0;
    transition: 350ms;
}
.mobileNav{
    z-index: 999;
}
.closeICons {
    color: #fff;
    padding-left: 10px;
    padding-bottom: 20px;
}
.mobile_nav_bar .nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
}
.mobile_nav_bar .nav-link a {
    color: #e3e3e3 !important;
    line-height: 50px;
}
.popular_detail_sec {
    overflow: hidden;
}
.subNewsLetter {
    display: flex;
    align-items: flex-start;
}

.css-1eoe787-MuiSlider-markLabel {
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    line-height: 1.43 !important;
    letter-spacing: 0.01071em !important;
    color: #3d3d3d !important;
    position: absolute !important;
    white-space: nowrap !important;
    top: 30px !important;
    transform: translateX(-50%) !important;
}

.css-sxo8tj {
    font-family: 'Poppins'!important;
    font-weight: 600!important;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.87);
    position: absolute;
    white-space: nowrap;
    top: 30px;
    transform: translateX(-50%);
}

.ml-0 {
    margin-left: 0px !important;
}

.delete-color {
    color: #f44336c9 !important;
}
.flight_desk.desk_ce.flightTimingList {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
span.flightTicketDate {
    font-size: 12px;
    font-weight: 600;
}
button.facilityBtn {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 1px 10px;
    font-size: 13px;
    margin-top: 10px;
}
.user_menu_list.user_mobile_list{
  display: none;
}
.banner_overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #00000026;
    z-index: 2;
}
button.copy_user_btn {
    border: 1px solid #067fc0;
    margin: 10px 20px;
    padding: 7px 25px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 4px;
    background-color: #fff;
}
.plusIcons {
    font-size: 18px !important;
    color: #067fc0;
}
.dis_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
span.minmax_title {
    font-size: 12px;
    font-weight: 600;
}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
    font-size: 30px;
    color: #fff;
    opacity: 0.8;
}