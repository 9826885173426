
  .education {
    --bg-color: #ffd861;
    --bg-color-light: #ffeeba;
    --text-color-hover: #4C5656;
    --box-shadow-color: rgba(255, 215, 97, 0.48);
  }
  
  .credentialing {
    --bg-color: #B8F9D3;
    --bg-color-light: #e2fced;
    --text-color-hover: #4C5656;
    --box-shadow-color: rgba(184, 249, 211, 0.48);
  }
  
  .wallet {
    --bg-color: #CEB2FC;
    --bg-color-light: #F0E7FF;
    --text-color-hover: #fff;
    --box-shadow-color: rgba(206, 178, 252, 0.48);
  }
  
  .human-resources {
    --bg-color: #DCE9FF;
    --bg-color-light: #f1f7ff;
    --text-color-hover: #4C5656;
    --box-shadow-color: rgba(220, 233, 255, 0.48);
  }
  .card {
    width: 30%;
    height: 60vh;
    background: #fff !important;
    border-top-right-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-shadow: 0 14px 26px rgb(0 0 0 / 20%);
    transition: all 0.3s ease-out;
    text-decoration: none;
    margin: auto;
    border: none !important;
    align-items: center;
    border-radius: 1rem !important;
}
.card:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgb(0 0 0 / 11%), 0 24px 46px rgb(0 0 0 / 15%);
}
  
  .card:hover .overlay {
    transform: scale(4) translateZ(0);
    opacity: 100%;
  }
  
  .card:hover .circle {
    border-color: #a7d5f2;
    background: #1587d4;
  }
  
  .card:hover .circle:after {
    background: #363636;
}
  
  .card:hover p {
    color: var(--text-color-hover);
  }
  
  .card:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0,0,0,0.11),
      0 15px 24px var(--box-shadow-color);
  }
  
  .card p {
    font-size: 17px;
    color: #4C5656;
    margin-top: 30px;
    z-index: 1000;
    transition: color 0.3s ease-out;
  }
  
  .circle {
    width: 131px;
    height: 131px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #1587d4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
    margin: auto;
}
  
  .circle:after {
    content: "";
    width: 118px;
    height: 118px;
    display: block;
    position: absolute;
    background: #1587d4;
    border-radius: 50%;
    top: 7px;
    left: 7px;
    transition: opacity 0.3s ease-out;
  }
  
  .circle svg {
    z-index: 10000;
    transform: translateZ(0);
  }
  .overlay {
    width: 118px;
    position: absolute;
    height: 118px;
    border-radius: 50%;
    background: #1587d4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    margin: auto;
    transition: transform 0.3s ease-out;
    opacity: 0;
}
  .circle svg {
    color: #fff;
    font-size: 75px;
}
.card:hover .circle svg {
    color: #ffffff;
}
.yor_booking_con {
    text-align: center;
    z-index: 99999;
}
.yor_booking_con h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 2rem 0 0 0;
    text-transform: capitalize;
}
.card:hover .yor_booking_con h3 {
    color: #ffffff;
    z-index: 99999;
}
.hotel_confirm_pas_det {
    padding: 4rem 0;
}
.hotel_booking_tabel {
    padding-bottom: 3rem;
}
.hotel_booking_tabel thead.thead-dark {
    background: #1587d4 !important;
    color: #fff;
}
.hotel_booking_tabel thead.thead-dark th {
    font-size: 16px;
    font-weight: 400;
    color: #efefef;
    padding: 13px;
}
.hotel_booking_tabel tr {
    background: #efefef;
}
.hotel_booking_tabel th {
  background: #087ac4;
  color: #fff;
  width: 20%;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 13px;
  padding: 1rem;
}
.hotel_booking_tabel td {
  color: #4c4c4c;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 13px;
  padding: 1rem;
  width: 80%;
}
.roo_typs ul li {
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
}
.roo_typs ul span.bld_txt {
    font-weight: 700;
    color: #161616;
    margin-right: 0.5rem;
}
.hotel_boocking_confirm_page {
    background:url(../../asset/hotel-booking.jpg);
    height: 82vh;
    display: flex;
    align-items: center;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.hotel_confirm_pas_det .traveller_list .wrapper-traveller {
    width: 100%;
}
.hotel_confirm_pas_det .right {
    width: 100%;
    background: #ffffff;
    padding: 1rem;
    box-shadow: 0px 0px 15px 0px #bfbfbf;
    border-radius: 10px;
}
.roo_typs {
    margin: 1rem 0;
    border-bottom: 1px solid #00000030;
    background: #f6c22036;
    padding: 1rem;
    border-radius: 10px;
}

.hotel_confirm_pas_det h3 {
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px !important;
}
.hotel_confirm_pas_det .info_data {
    text-align: center;
}
h6.add_room_count {
    font-size: 14px;
    color: #000;
    width: 100%;
    font-weight: 500;
    word-spacing: 2px;
}
h6.add_room_count span {
    color: #0543e9;
    padding: 0px 6px;
    background: #fff;
    border-radius: 50%;
}
button.guest_hotel_add {
    background: #173ccb !important;
    border: 1px solid #00000036;
    color: #fff !important;
    border-radius: 10px !important;
    margin-bottom: 1rem;
    margin: 1rem 0 !important;
    font-size: 14px;
    text-transform: capitalize;
}
button.view_ticket_flight {
  border: 1px solid;
  padding: 3px 10px;
  font-size: 13px;
  font-weight: 500;
  color: #1d8ddc;
}