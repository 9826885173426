:root {
  --primary-color: #152334;
  --primary-color-light: #A2D1D6;
  --white-color: #f9f9f9;

}

:focus {
  outline: none;
}

.flightname {
  font-size: 17px;
  font-weight: 600;
  font-family: 'Poppins';
  color: #000000 !important;
  width: 100%;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.card-ui-container {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  -webkit-box-shadow: 5px 5px 15px 1px rgba(21, 35, 52, 0.2);
  -moz-box-shadow: 5px 5px 15px 1px rgba(21, 35, 52, 0.2);
  box-shadow: 5px 5px 15px 1px rgba(21, 35, 52, 0.2);
}


.course-container {
  flex: 30% 1;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 26px;
}

.chapter-container {
  flex: 70% 1;
  background-color: var(--white-color);
  padding: 26px;
  border-radius: 0 3px 3px 0;
}

.course {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.course .subtitle {
  margin: 0 0 12px 0;
}

.course .title {
  margin: 0;

}

.course .more {
  position: absolute;
  bottom: 0;
  color: var(--primary-color-light);
  text-decoration: none;
  font-size: 12px;

  cursor: pointer;
  transition: 0.3s all;
}

.course .more:hover {
  color: var(--white-color);
}

.course .more ion-icon {
  vertical-align: middle;
}

.chapter {
  position: relative;
  height: 100%;
}


.chapter .chapter_header {
  display: flex;
  flex-direction: row;
}

.chapter_number {
  flex: 1;
  margin: 0;
}

.chapter_progress {
  flex: 1;
}

.chapter_progress .line_progress {
  position: relative;
  height: 4px;
  width: 180px;
  background-color: var(--primary-color-light);
  margin: 6px 0 6px auto;
}

.chapter_progress .line_progress:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 30px;
  background-color: var(--primary-color);
}

.chapter_progress .progress_challenges {
  display: block;
  font-size: 8px;
  text-align: right;
  margin: 0;
}


.chapter .title {
  margin: 0;
  color: var(--primary-color);
}

.chapter .chapter_btn {
  position: absolute;
  bottom: 0;
  right: 0;

  background: var(--primary-color);
  color: var(--white-color);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  transition-property: color;
  transition-duration: .5s;
}

.chapter .chapter_btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: .5s;
  transition-timing-function: ease-out;
  background: var(--primary-color-light);
}

.chapter .chapter_btn:hover {
  color: var(--primary-color);
}

.chapter .chapter_btn:hover::before {
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(.52, 1.64, .37, .66);
}

/* GENERALS*/

.course_first_heading {
  text-transform: uppercase;
  margin: 0 0 12px 0;
  color: var(--primary-color-light);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
}

.course_principal_heading {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  font-weight: 400;
}

.btn {

  display: block;
  cursor: pointer;
  padding: 12px 24px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  border: none;
}

@media (max-width: 767px) {
  .card-ui-container {
    flex-direction: column;
    width: 85%;
    min-width: 400px;
  }

  .course .more {
    right: 0;
  }

  .chapter {
    min-height: 120px;
  }

  .chapter .title {
    width: 50%;
    margin: 24px 0;
  }

  .chapter .chapter_btn {
    position: relative;
    margin: 0 0 0 auto;
  }
}

.flight_list_sec .css-bsph2u-MuiTableCell-root {
  font-family: 'Poppins' !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: none;
  text-align: left;
  padding: 0px;
}

.css-w8dmq8 {
  font-family: 'Poppins' !important;
}

span.flight_from {
  font-size: 12px;
  color: #8f8f8f;
}

.destinamtion_flight {
  align-items: center !important;
  justify-content: center;
  text-align: center;
}

.desk_ce {
  margin: 0 4rem;
}

span.flight_timr {
  font-size: 17px;
  font-weight: 600;
}

span.flight_when {
  font-size: 12px;
  color: #8f8f8f;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: none !important;
}

span.flight_hrs {
  font-size: 11px;
  font-weight: 500;
}

button.book_tick {
  background: #0543e9 !important;
  color: #fff !important;
  padding: 10px 50px !important;
  border-radius: 30px !important;
}

.flight_list_sec .MuiAccordionSummary-content {
  margin: 12px 0;
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  justify-content: space-between;
  align-items: center;
}
button.book_tick {
  background: #0041f2;
  color: #fff !important;
  padding: 6px 20px !important;
  border-radius: 30px !important;
  border: none !important;
  box-shadow: 0px 0px 10px 1px #8d8d8dc2;
}
button.book_tick a {
  color: #fff !important;
}

button.book_tick_price {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Poppins';
  color: #3d3d3d;
}

span.rs {
  font-size: 13px;
  margin-right: 0.2rem;
}

.flight_list_sec .MuiPaper-elevation1 {
  box-shadow: none;
  border: 1px solid #ccccccad;
  text-align: center;
}

.ticket_padge {
  background: #f6c220;
  font-size: 10px;
  padding: 1px 10px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px 0 5px 0px;
  box-shadow: 1px 3px 4px 1px #c0c0c05e;
  color: #fff;
}

.desk_ce {
  position: relative;
}

.desk_ce::after {
  content: "";
  position: absolute;
  background: url(../../asset/images/icons/right.svg);
  top: 90%;
  bottom: 0;
  margin: auto;
  right: 3rem;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.desk_ce::before {
  width: 50px;
  height: 50px;
  content: "";
  position: absolute;
  background: url(../../asset/images/icons/left.svg);
  top: 90%;
  bottom: 0;
  margin: auto;
  left: 3rem;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.ticket_tab_con_head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  position: relative;
}

.flight_view_details p {
  color: #3d3d3d;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.view_flight_detail_btn {
  color: #0543e9 !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  word-spacing: 1px !important;
}
.view_flight_class {
  color: #000 !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  word-spacing: 1px !important;
  text-transform: capitalize !important;
}
.trip_price_slider {
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.traveldateSlide button.owl-prev span {
  position: absolute !important;
  left: -3rem !important;
  top: 1.2rem !important;
  background: #f6f6f6 !important;
  padding: 20px 5px !important;
  border-radius: 20px !important;
}

.traveldateSlide button.owl-next span {
  position: absolute !important;
  right: -3rem !important;
  top: 1.2rem !important;
  background: #f6f6f6 !important;
  padding: 20px 5px !important;
  border-radius: 20px !important;
}

.traveldateSlide .owl-nav {
  display: block !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.traveldateSlide .owl-nav .disabled {
  opacity: 100%;
}

.p-r {
  margin-bottom: 1.5rem;
  border-radius: 5px;
  position: relative;
  border: 1px solid #e7e7e7;
}

.flight_dropdown_details .trip_price_slider h3 {
  font-size: 10px;
  line-height: 1.5;
  color: #939393;
  font-weight: 500;
  margin-bottom: 0rem;
}

.flight_dropdown_details span.trip_price {
  color: #3d3d3d;
  font-weight: 500;
  font-size: 11px;
}

.flight_dropdown_details .item:after {
  content: "";
  position: absolute;
  right: -2rem;
  width: 1px;
  height: 50px;
  background: #cccccc;
  top: 0;
  bottom: 0;
  margin: auto;
}

.flight_dropdown_details .trip_price_slider .item {
  padding: 0.5rem 0;
  position: relative;
  text-align: center;
}

.trip_price_slider h3 svg {
  position: absolute;
  left: -2rem;
  font-size: 12px;
  top: 1rem;
  background: #f5c220;
  padding: 5px;
  color: #fff;
  border-radius: 50px;
}

.tb_bnl .Mui-selected span {
  color: #000 !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(0 0 0 / 49%) !important;
}

.css-1wnsr1i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50% !important;
  background-color: #fff;
  border: none;
  box-shadow: none !important;
  padding: 32px;
}

.comlete_head ul li {
  display: flex;
  float: left;
  margin-right: 0.5rem;
  font-size: 15px;
  color: #2f399a;
  font-weight: 600;
  text-transform: uppercase;
}

.comlete_head h5 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}

.comlete_head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0000001f;
  align-items: center;
}

.comlete_head h5 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.flight_payment_pop.MuiBox-root.css-1wnsr1i {
  border: 0;
  border-radius: 20px;
  width: 80% !important;
}

.flight_pop_contentent {
  padding-top: 1.5rem;
}

.flight_name_pop .css-bsph2u-MuiTableCell-root {
  padding: 0;
}

span.flight_no_pop {
  color: #666666;
  font-size: 16px;
  font-weight: 600;
}

.flight_pop_contentent .card {
  border-radius: 5px !important;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 20%) !important;
  background-color: #e3e3e3 !important;
  padding: 5px 10px !important;
  position: relative !important;
  width: 70% !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  border-style: dotted !important;
  border-color: #7a7a7aa8 !important;
  text-align: start !important;
  display: block !important;
  margin-bottom: 1.5rem;
}

.card::after {
  position: absolute;
  content: "";
  height: 30px;
  right: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  width: 30px;
  margin: auto;
}

.card::before {
  position: absolute;
  content: "";
  height: 30px;
  left: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  width: 30px;
  margin: auto;
}

.co-img img {
  width: 100px;
  height: 100px;
}

.vertical {
  border-left: 5px dotted black;
  height: 100px;
  position: absolute;
  left: 40%;
}

.content h1 {
  font-size: 35px;
  margin-left: -20px;
  color: #565656;
}

.content h1 span {
  font-size: 18px;
}

.content h2 {
  font-size: 18px;
  margin-left: -20px;
  color: #565656;
  text-transform: uppercase;
}

.content p {
  font-size: 16px;
  color: #696969;
  margin-left: -20px;
}

.copy-button {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.copy-button input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.copy-button button {
  padding: 5px 20px;
  background-color: #dc143c;
  color: #fff;
  border: 1px solid transparent;
}

.buy {
  position: absolute;
  content: "";
  bottom: 20px;
  left: 20px;
  background-color: #dc143c;
}

.co-img ul li {
  font-size: 22px;
  font-weight: 500;
  color: #707070;
  text-transform: uppercase;
  padding: 0 1rem;
}

.ticket_carrd_per {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

span.per_details {
  font-size: 12px;
  color: #3d3d3d;
  font-weight: 500;
  text-transform: uppercase;
  width: 30%;
}

.per_count {
  font-size: 15px;
  color: #323232;
  font-weight: 500;
  text-transform: uppercase;
}

/* .flight_name_pop {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.trip-in .MuiGrid-item {
  position: relative !important;
}

span.alert {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #309ff2;
  font-family: 'Poppins';
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
  left: 1rem;
}

.content-line {
  border-left: 2px solid #ddd;
  padding-left: 30px;
  width: 300px;


}

.content-line .box-line:nth-child(2) {
  background: #45B4EC;
}

.content-line .box-line:nth-child(2):after {
  border-color: transparent #45B4EC transparent transparent;
}

.content-line .box-line:nth-child(3) {
  background: #CE45EC;
}

.content-line .box-line:nth-child(3):after {
  border-color: transparent #CE45EC transparent transparent;
}

.content-line .box-line {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 8px;
  background: #ddd;
  margin-bottom: 10px;
  font-family: sans-serif;
  position: relative;

}

.box-line:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  border: 3px solid #ddd;
  position: absolute;
  top: 10px;
  left: -39px;
}

.box-line:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;

  border-color: transparent #ddd transparent transparent;
  border-width: 8px;

  position: absolute;
  left: -16px;
  top: 10px;
}

.flight_pop_contentent p.MuiTypography-root.MuiTypography-body1 {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins';
  color: #3d3d3d;
  margin-left: 1rem;
}

p.booking_step {
  font-size: 13px !important;
  position: absolute;
  left: -1.5rem;
  background: #0747b5;
  color: #fff !important;
  z-index: 1;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 2;
  padding-left: 0 !important;
}

.flight_pop_contentent .MuiPaper-elevation1 {
  box-shadow: none;
  border-top: 0.1px solid #d5d5d5;
}

.flight_pop_contentent span.MuiIconButton-label {
  border: 1px solid #d0d0d0;
  border-radius: 50px;
}

.flight_pop_accourdian span {
  font-weight: 500;
  font-size: 13px;
  font-family: 'Poppins';
}

.privay_check_box {
  padding-top: 1rem;
}

.MuiAccordion-root:before {
  display: none !important;
}

.MuiAccordionSummary-root {
  display: flex;
  padding: 0px 16px;
  min-height: 48px;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #efefef;
}

.css-bsph2u-MuiTableCell-root {
  border-bottom: none !important;
}

.pop_con {
  margin-top: 1.5rem !important;
}

.pop_total_card_single {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #ffffff40;
  font-size: 16px;
  font-weight: 400;
}

.pop_total_card {
  border-radius: 5px;
  background: #084ec7;
  padding: 0.5rem 1rem;
  color: #dce8f3;
  display: block;
}

.pop_total_card_single.nd-head {
  border-top: none;
  text-transform: uppercase;
  font-weight: 600;
}

.pop_total_card_single.total_price {
  padding: 5px 0.8rem;
  background: hsl(218deg 80% 27%);
  margin-top: 0.5rem;
}

.pop_total_card {
  border-radius: 5px;
  background: #084ec7;
  padding: 1rem 1rem;
  color: #dce8f3;
  display: block;
  position: relative;
  margin-top: 20px;
}

span.sm_text {
  font-size: 11px;
  font-weight: 200;
}

.pop_total_card::after {
  content: "";
  position: absolute;
  bottom: -0.7rem;
  width: 80%;
  height: 3rem;
  background: #7fa2dc;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  border-radius: 0 0 5px 5px;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.history-tl-container {
  margin: auto;
  display: block;
  position: relative;
  margin: 1rem 0;
}

.history-tl-container ul.tl {
  margin: 20px 0;
  padding: 0;
  display: inline-block;

}

.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  border-left: 1px dashed #9e9e9e;
  padding: 0 0 0px 30px;
  position: relative;
  height: 6rem;
}

.history-tl-container ul.tl li:last-child {
  border-left: 0;
}

.history-tl-container ul.tl li::before {
  position: absolute;
  left: -0.7rem;
  top: 0;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #0543e9;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.history-tl-container ul.tl li:hover::before {
  border-color: #9e9e9e;
  transition: all 1000ms ease-in-out;
}

ul.tl li .item-detail {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

ul.tl li .timestamp {
  color: #8D8D8D;
  position: absolute;
  width: 100px;
  left: -50%;
  text-align: right;
  font-size: 12px;
}

.history-tl-container .item-title {
  font-weight: 600;
  color: #3d3d3d;
}

.history-tl-container ul.tl li:last-child {
  height: auto;
}

.traveldateSlide .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #0543e9;
  text-decoration: none;
}

button.addmore_city_btns {
  border: none;
  background: none;
  position: relative;
}

button.addmore_city_btns svg {
  position: relative;
  background: #0543e9;
  width: 25px;
  height: 25px;
  margin: auto;
  margin-left: 0;
  border-radius: 50px;
}

button.remove_slight {
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
}

button.remove_slight svg {
  position: relative;
  background: #0543e9;
  width: 25px;
  height: 25px;
  margin-left: 0;
  border-radius: 50px;
}

.trip-in {
  padding: 1rem;
  background-color: #f0f4f7;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none !important;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.text-muted {
  color: #dc3545 !important;
}

.trip-in .css-319lph-ValueContainer {
  padding: 2px 0px;
}

.select_flight td {
  padding: 0;
}

.book_btm {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.book_flight_stop span.rs {
  font-size: 12px;
  font-weight: 600 !important;
  color: #3d3d3d;
  margin-right: 0.5rem;
}

button.book_flight_stop {
  background: #efefef;
  font-size: 12px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 700;
  color: #000000;
  border-radius: 0;
}
.travellerAddPop button {
  margin-right: 1rem;
}
.hotelPayment_searchVal {
  background: #f7f7f7;
}
.booking_values {
  margin-right: 1rem;
}